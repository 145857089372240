// import React, { useState, useEffect } from 'react';
// import './YourOrders.css';
// import axios from 'axios';
// import Navbar from '../../../features/navbar/Navbar';
// import { useNavigate, useParams } from 'react-router-dom';

// const YourOrders = () => {
//     const { pk } = useParams();
//     const [orders, setOrders] = useState([]);
//     const navigate = useNavigate();
//     const [products, setProducts] = useState([]);
//     const [searchTerm, setSearchTerm] = useState('');
//     useEffect(() => {
//         if (searchTerm.trim() !== '') {
//           const fetchSearchResults = async () => {
//             try {
//               const response = await axios.get(
//                 `https://terracharms.com/user_search_get/${pk}/`,
//                 {
//                   params: {
//                     search: searchTerm
//                   }
//                 }
//               );
//               setProducts(response.data);
      
//               // Redirect to the search results page
//               navigate(`/user/allproducts/${pk}/`);
//             } catch (error) {
//               console.error('Error fetching search results:', error);
//             }
//           };
      
//           fetchSearchResults();
//         }
//       }, [searchTerm, pk, navigate, setProducts]);
      
//       const handleSearch = (e) => {
//         setSearchTerm(e.target.value);
//       };
//     useEffect(() => {
//         // Fetch orders when component mounts
//         axios.get(`https://terracharms.com/your_orders/${pk}/`)
//             .then(response => {
//                 setOrders(response.data);
//             })
//             .catch(error => {
//                 console.error('Error fetching orders:', error);
//             });
//     }, [pk]);

//     const handleCancelOrder = (order_id) => {
//         const confirmCancel = window.confirm('Are you sure you want to cancel this order?');
//         if (confirmCancel) {
//             axios.delete(`https://terracharms.com/cancel_user_order/${pk}/${order_id}/`)
//                 .then(response => {
//                     alert(response.data.msg);
//                     setOrders(orders.filter(order => order.order_id !== order_id));
//                 })
//                 .catch(error => {
//                     console.error('Error canceling order:', error);
//                     alert('There was an error canceling the order.');
//                 });
//         }
//     };

//     const handleReturnOrder = (order_id) => {
//         const confirmReturn = window.confirm('Are you sure you want to return this order?');
//         if (confirmReturn) {
//             axios.post(`https://terracharms.com/return_orders/${order_id}/`)
//                 .then(response => {
//                     alert('Order returned successfully.');
//                 })
//                 .catch(error => {
//                     console.error('Error returning order:', error);
//                     alert('There was an error processing your return.');
//                 });
//         }
//     };

//     return (
//         <>
//       <Navbar onSearch={setSearchTerm} />
//             <div className='yourorders'>
//                 <h1 className='mainhead1'>Your Orders</h1>

//                 <table className='yourorderstable'>
//                     <thead>
//                         <tr>
//                             <th scope='col'>Order ID</th>
//                             <th scope='col'>Order Date</th>
//                             <th scope='col'>Order Status</th>
//                             <th scope='col'>Total</th>
//                             <th scope='col'>Invoice</th>
//                             <th scope='col'>Return</th>
//                             <th scope='col'>Cancel</th>
//                         </tr>
//                     </thead>

//                     <tbody>
//                         {orders.map((orderdata, index) => (
//                             <tr key={index}>
//                                 <td data-label='Order ID'>{orderdata.order_id}</td>
//                                 <td data-label='Order Date'>{orderdata.order_date}</td>
//                                 <td data-label='Order Status'>{orderdata.order_status}</td>
//                                 <td data-label='Total'>₹{orderdata.Total}</td>
//                                 <td data-label='Invoice'>
//                                     <button
//                                         className="nextbtn"
//                                         onClick={() => {
//                                             navigate(`/user/invoice/${pk}/${orderdata.order_id}/`);
//                                         }}
//                                     >
//                                         View Invoice
//                                     </button>
//                                 </td>
//                                 <td data-label='Return'>
//                                     <button
//                                         className="nextbtn"
//                                         onClick={() => handleReturnOrder(orderdata.order_id)}
//                                     >
//                                         Return Order
//                                     </button>
//                                 </td>
//                                 <td data-label='Cancel'>
//                                     <button
//                                         className="nextbtn"
//                                         onClick={() => handleCancelOrder(orderdata.order_id)}
//                                     >
//                                         Cancel Order
//                                     </button>
//                                 </td>
//                             </tr>
//                         ))}
//                     </tbody>
//                 </table>
//             </div>
//         </>
//     );
// };

// export default YourOrders;

import React, { useState, useEffect } from 'react';
import './YourOrders.css';
import axios from 'axios';
import Navbar from '../../../features/navbar/Navbar';
import { useNavigate, useParams } from 'react-router-dom';

const YourOrders = () => {
    const { pk } = useParams();
    const [orders, setOrders] = useState([]);
    const navigate = useNavigate();
    const [searchTerm, setSearchTerm] = useState('');

    useEffect(() => {
        if (searchTerm.trim() !== '') {
            const fetchSearchResults = async () => {
                try {
                    const response = await axios.get(
                        `https://terracharms.com/user_search_get/${pk}/`,
                        {
                            params: { search: searchTerm },
                        }
                    );
                    navigate(`/user/allproducts/${pk}/`);
                } catch (error) {
                    console.error('Error fetching search results:', error);
                }
            };
            fetchSearchResults();
        }
    }, [searchTerm, pk, navigate]);

    const handleSearch = (e) => {
                setSearchTerm(e.target.value);
              };
            useEffect(() => {
                // Fetch orders when component mounts
                axios.get(`https://terracharms.com/your_orders/${pk}/`)
                    .then(response => {
                        setOrders(response.data);
                    })
                    .catch(error => {
                        console.error('Error fetching orders:', error);
                    });
            }, [pk]);
    const handleCancelOrder = (order_id) => {
                const confirmCancel = window.confirm('Are you sure you want to cancel this order?');
                if (confirmCancel) {
                    axios.delete(`https://terracharms.com/cancel_user_order/${pk}/${order_id}/`)
                        .then(response => {
                            alert(response.data.msg);
                            setOrders(orders.filter(order => order.order_id !== order_id));
                        })
                        .catch(error => {
                            console.error('Error canceling order:', error);
                            alert('There was an error canceling the order.');
                        });
                }
            };
        
    const handleReturnOrder = (order_id) => {
                const confirmReturn = window.confirm('Are you sure you want to return this order?');
                if (confirmReturn) {
                    axios.post(`https://terracharms.com/return_orders/${order_id}/`)
                        .then(response => {
                            alert('Order returned successfully.');
                        })
                        .catch(error => {
                            console.error('Error returning order:', error);
                            alert('There was an error processing your return.');
                        });
                }
            };

    return (
        <>
            <Navbar onSearch={setSearchTerm} />
            <div className='yourorders'>
                <h1 className='mainhead1'>Your Orders</h1>
                <div className='orders-container'>
                    {orders.map((orderdata, index) => (
                        <div className='order-card' key={index}>
                            <h3>Order ID: {orderdata.order_id}</h3>
                            <div className='order-details'>
                                <div className='order-item'>
                                    <span>Order Date:</span>
                                    <span>{orderdata.order_date}</span>
                                </div>
                                <div className='order-item'>
                                    <span>Order Status:</span>
                                    <span>{orderdata.order_status}</span>
                                </div>
                                <div className='order-item'>
                                    <span>Total:</span>
                                    <span>₹{orderdata.Total}</span>
                                </div>
                            </div>
                            <div className='button-container'>
                                <button
                                    className='action-btn invoice-btn'
                                    onClick={() => navigate(`/user/invoice/${pk}/${orderdata.order_id}/`)}
                                >
                                    View Invoice
                                </button>
                                <button
                                    className='action-btn return-btn'
                                    onClick={() => handleReturnOrder(orderdata.order_id)}
                                >
                                    Return
                                </button>
                                <button
                                    className='action-btn cancel-btn'
                                    onClick={() => handleCancelOrder(orderdata.order_id)}
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
};

export default YourOrders;
