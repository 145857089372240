import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, Link } from 'react-router-dom';
import axios from 'axios';

export default function Login() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [mobileNumber, setMobileNumber] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [isMobileNumberFilled, setIsMobileNumberFilled] = useState(false);


  const handleLogin = async (e) => {
    e.preventDefault();
    if (!isMobileNumberFilled) {
      setError('Please fill in your mobile number.');
      return;
    }
    setLoading(true);
    setError('');
    
    try {
      const response = await axios.patch('https://terracharms.com/login_admin_otp/', {
        Mobile_no: mobileNumber,
      });

      if (response.status === 200) {
        console.log(response.data);
        const pk = response.data.pk;
        
        // Nested condition to check if the user is active
        if (response.data.is_active === true) {
          navigate(`/admin/${pk}/`);
        } else {
          navigate(`/adminotp/${pk}/`);
        }
      }
    } catch (error) {
      if (error.response && error.response.data) {
        setError(error.response.data.error);
      } else {
        setError('An error occurred during signup.');
      }
    } finally {
      setLoading(false);
    }
  };
  const handleMobileNumberChange = (event) => {
    setMobileNumber(event.target.value);
    setIsMobileNumberFilled(event.target.value !== ''); // Check if the mobile number field is filled
  };
  

  return (
    <>
      <div className="mx-auto mt-12 bg-white max-w-2xl px-4 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
        <img
                  className="h-16 w-16 ml-36"
                  src="https://cdn.dotpe.in/longtail/store-logo/8631177/mvNA0s1i.webp"
                  alt="StarkMart"
                />
          <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
            Log in to your account
          </h2>
        </div>

        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
          <form className="space-y-6" onSubmit={handleLogin}>
            <div>
              <label htmlFor="mobileNumber" className="block text-sm font-medium leading-6 text-gray-900">
                Mobile Number
              </label>
              <div className="mt-2">
                <input
                  type="number"
                  id="mobileNumber"
                  value={mobileNumber}
                  onChange={handleMobileNumberChange}
                  placeholder="Enter your mobile number"
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div>
              <button
                type="submit"
                disabled={!isMobileNumberFilled || loading}
                className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                {loading ? 'Logging in...' : 'Log in'}
              </button>
            </div>

            {error && <p className="mt-4 text-center text-sm text-red-500">{error}</p>}
          </form>

          <p className="mt-10 text-center text-sm text-gray-500">
            Not a member?{' '}
            <Link to="/adminsignup" className="font-semibold leading-6 text-indigo-600 hover:text-indigo-500">
              Create an Account
            </Link>
          </p>
        </div>
      </div>
    </>
  );
}
