
import React from "react";
import { Link,NavLink,useParams } from "react-router-dom";
import $ from "jquery"; // Assuming you have jQuery installed
import axios from 'axios';

const Header = () => {
  const { pk } = useParams(); 
  const handleLogout = async () => {
    try {
      const response = await axios.patch(`https://terracharms.com/seller_logout/${pk}/`);
      
      if (response.status === 200) {
        window.location.href = '/sellerlogin'; // Redirect to seller login page after logout
      }
    } catch (error) {
      console.error('Error during logout:', error);
      // Handle the error as needed
    }
  };

  React.useEffect(() => {
    $("[data-trigger]").on("click", function (e) {
      e.preventDefault();
      e.stopPropagation();
      var offcanvas_id = $(this).attr("data-trigger");
      $(offcanvas_id).toggleClass("show");
    });

    $(".btn-aside-minimize").on("click", function () {
      if (window.innerWidth < 768) {
        $("body").removeClass("aside-mini");
        $(".navbar-aside").removeClass("show");
      } else {
        // minimize sidebar on desktop
        $("body").toggleClass("aside-mini");
      }
    });
  }, []);

  return (
    // <header className="main-header navbar">
    //   <div className="col-nav justify-content-end">
    //     <button
    //       className="btn btn-icon btn-mobile me-auto"
    //       data-trigger="#offcanvas_aside"
    //     >
    //       <i className="md-28 fas fa-bars"></i>
    //     </button>
    //     <ul className="nav">
    //       <li className="dropdown nav-item">
    //         <Link className="dropdown-toggle" data-bs-toggle="dropdown" to="#">
    //           <img
    //             className="img-xs rounded-circle"
    //             src="/images/user.png"
    //             alt="User"
    //           />
    //         </Link>
    //         <div className="dropdown-menu dropdown-menu-end">
    //           <Link className="dropdown-item" to={`/seller/Profile/${pk}/`}>
    //             My profile
    //           </Link>
    //           <button className="dropdown-item text-danger" onClick={handleLogout}>
    //             Exit
    //           </button>
    //         </div>
    //       </li>
    //     </ul>
    //   </div>
    // </header>
    <>
    <header className="main-header navbar mobile-header d-block d-md-none">
  <div className="col-nav">
    <button
      className="btn btn-icon btn-mobile"
      onClick={() => document.getElementById('offcanvas_aside').classList.toggle('show')}
    >
      <i className="md-28 fas fa-bars"></i>
    </button>
    <div className="dropdown nav-item">
      <Link className="dropdown-toggle" data-bs-toggle="dropdown" to="#">
        <img
          className="img-xs rounded-circle"
          src="/images/user.png"
          alt="User"
        />
      </Link>
      <div className="dropdown-menu dropdown-menu-end">
        <Link className="dropdown-item" to={`/seller/Profile/${pk}`}>
          My profile
        </Link>
        <button className="dropdown-item text-danger" onClick={handleLogout}>
          Exit
        </button>
      </div>
    </div>
  </div>
</header>

{/* Desktop Header */}
<header className="main-header navbar desktop-header d-none d-md-flex">
  <div className="col-nav justify-content-end">
    <ul className="nav">
      <li className="dropdown nav-item">
        <Link className="dropdown-toggle" data-bs-toggle="dropdown" to="#">
          <img
            className="img-xs rounded-circle"
            src="/images/user.png"
            alt="User"
          />
        </Link>
        <div className="dropdown-menu dropdown-menu-end">
          <Link className="dropdown-item" to={`/seller/Profile/${pk}`}>
            My profile
          </Link>
          <button className="dropdown-item text-danger" onClick={handleLogout}>
            Exit
          </button>
        </div>
      </li>
    </ul>
  </div>
</header>

{/* Mobile Sidebar */}
<aside className="navbar-aside mobile-sidebar" id="offcanvas_aside">
  <div className="brand-wrap">
    <Link to={`/seller/${pk}/`} className="brand-link">
      <img
        src="https://cdn.dotpe.in/longtail/store-logo/8631177/mvNA0s1i.webp"
        className="h-16"
        alt="Ecommerce dashboard template"
      />
      <h2 className="font-bold">The StarkMart</h2>
    </Link>
    <button className="btn btn-icon btn-aside-minimize" onClick={() => document.getElementById('offcanvas_aside').classList.toggle('show')}>
      <i className="text-muted fas fa-stream"></i>
    </button>
  </div>

  <ul className="menu-aside">
    <li className="menu-item">
    <NavLink
                activeClassName="active"
                className="menu-link"
                to={`/sellerdash/${pk}/`}
                exact={true}
              >
                <i className="icon fas fa-home"></i>
                <span className="text">Dashboard</span>
              </NavLink>
            </li>
            <li className="menu-item">
              <NavLink
                activeClassName="active"
                className="menu-link"
                to={`/seller/products/${pk}/`}
              >
                <i className="icon fas fa-shopping-bag"></i>
                <span className="text">Inventory</span>
              </NavLink>
            </li>
            <li className="menu-item">
              <NavLink
                activeClassName="active"
                className="menu-link"
                to={`/seller/addproduct/${pk}/`}
              >
                <i className="icon fas fa-cart-plus"></i>
                <span className="text">Upload Catalog</span>
              </NavLink>
            </li>            
            <li className="menu-item">
              <NavLink
                activeClassName="active"
                className="menu-link"
                to={`/seller/orders/${pk}/`}
              >
                <i className="icon fas fa-bags-shopping"></i>
                <span className="text">Orders</span>
              </NavLink>
    </li>
  </ul>
</aside>
</>
  );
};

export default Header;
