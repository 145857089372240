import React from "react";
import { Link,  NavLink,useParams } from "react-router-dom";
import $ from "jquery"; // Ensure you have jQuery installed
import axios from 'axios';
import './Header.css'; // Import CSS file

const Header = () => {
  const { pk } = useParams(); // Assuming pk is used correctly here

  // Function to handle logout
  const handleLogout = async () => {
    try {
      const response = await axios.patch(`https://terracharms.com/admin_logout/${pk}/`);
      
      if (response.status === 200) {
        window.location.href = '/admin/login'; // Redirect to seller login page after logout
      }
    } catch (error) {
      console.error('Error during logout:', error);
      // Handle the error as needed
    }
  };

  // Initialize jQuery plugins using useEffect
  React.useEffect(() => {
    // Offcanvas toggle click handler
    $("[data-trigger]").on("click", function (e) {
      e.preventDefault();
      e.stopPropagation();
      var offcanvas_id = $(this).attr("data-trigger");
      $(offcanvas_id).toggleClass("show");
    });

    // Minimize sidebar click handler
    $(".btn-aside-minimize").on("click", function () {
      if (window.innerWidth < 768) {
        $("body").removeClass("aside-mini");
        $(".navbar-aside").removeClass("show");
      } else {
        // Minimize sidebar on desktop
        $("body").toggleClass("aside-mini");
      }
    });
  }, []);

  return (
    <>
        <header className="main-header navbar mobile-header d-block d-md-none">
      <div className="col-nav">
        <button
          className="btn btn-icon btn-mobile"
          onClick={() => document.getElementById('offcanvas_aside').classList.toggle('show')}
        >
          <i className="md-28 fas fa-bars"></i>
        </button>
        <div className="dropdown nav-item">
          <Link className="dropdown-toggle" data-bs-toggle="dropdown" to="#">
            <img
              className="img-xs rounded-circle"
              src="/images/user.png"
              alt="User"
            />
          </Link>
          <div className="dropdown-menu dropdown-menu-end">
            <Link className="dropdown-item" to={`/admin/Profile/${pk}`}>
              My profile
            </Link>
            <button className="dropdown-item text-danger" onClick={handleLogout}>
              Exit
            </button>
          </div>
        </div>
      </div>
    </header>

    {/* Desktop Header */}
    <header className="main-header navbar desktop-header d-none d-md-flex">
      <div className="col-nav justify-content-end">
        <ul className="nav">
          <li className="dropdown nav-item">
            <Link className="dropdown-toggle" data-bs-toggle="dropdown" to="#">
              <img
                className="img-xs rounded-circle"
                src="/images/user.png"
                alt="User"
              />
            </Link>
            <div className="dropdown-menu dropdown-menu-end">
              <Link className="dropdown-item" to={`/admin/Profile/${pk}`}>
                My profile
              </Link>
              <button className="dropdown-item text-danger" onClick={handleLogout}>
                Exit
              </button>
            </div>
          </li>
        </ul>
      </div>
    </header>

    {/* Mobile Sidebar */}
    <aside className="navbar-aside mobile-sidebar" id="offcanvas_aside">
      <div className="brand-wrap">
        <Link to={`/admin/${pk}/`} className="brand-link">
          <img
            src="https://cdn.dotpe.in/longtail/store-logo/8631177/mvNA0s1i.webp"
            className="h-16"
            alt="Ecommerce dashboard template"
          />
          <h2 className="font-bold">The StarkMart</h2>
        </Link>
        <button className="btn btn-icon btn-aside-minimize" onClick={() => document.getElementById('offcanvas_aside').classList.toggle('show')}>
          <i className="text-muted fas fa-stream"></i>
        </button>
      </div>

      <ul className="menu-aside">
        <li className="menu-item">
          <NavLink activeClassName="active" className="menu-link" to={`/admin/${pk}`} exact>
            <i className="icon fas fa-home"></i>
            <span className="text">Dashboard</span>
          </NavLink>
        </li>
        <li className="menu-item">
          <NavLink activeClassName="active" className="menu-link" to={`/admin/products/${pk}`}>
            <i className="icon fas fa-shopping-bag"></i>
            <span className="text">Products</span>
          </NavLink>
        </li>
        <li className="menu-item">
          <NavLink activeClassName="active" className="menu-link" to={`/admin/addproduct/${pk}`}>
            <i className="icon fas fa-cart-plus"></i>
            <span className="text">Add product</span>
          </NavLink>
        </li>
        <li className="menu-item">
          <NavLink activeClassName="active" className="menu-link" to={`/admin/category/${pk}`}>
            <i className="icon fas fa-list"></i>
            <span className="text">Categories</span>
          </NavLink>
        </li>
        <li className="menu-item">
          <NavLink activeClassName="active" className="menu-link" to={`/admin/orders/${pk}`}>
            <i className="icon fas fa-bags-shopping"></i>
            <span className="text">Orders</span>
          </NavLink>
        </li>
        <li className="menu-item">
          <NavLink activeClassName="active" className="menu-link" to={`/admin/promo/${pk}`}>
            <i className="icon fa fa-bullhorn"></i>
            <span className="text">Promotions</span>
          </NavLink>
        </li>
        <li className="menu-item">
          <NavLink activeClassName="active" className="menu-link" to={`/admin/banner/${pk}`}>
            <i className="icon fa fa-bars"></i>
            <span className="text">Banners</span>
          </NavLink>
        </li>
        <li className="menu-item">
          <NavLink activeClassName="active" className="menu-link" to={`/admin/top/${pk}`}>
            <i className="icon fa fa-bars"></i>
            <span className="text">Top Selection</span>
          </NavLink>
        </li>
        <li className="menu-item">
          <NavLink activeClassName="active" className="menu-link" to={`/admin/users/${pk}`}>
            <i className="icon fas fa-user"></i>
            <span className="text">Users</span>
          </NavLink>
        </li>
        <li className="menu-item">
          <NavLink activeClassName="active" className="menu-link" to={`/admin/sellers/${pk}`}>
            <i className="icon fas fa-store-alt"></i>
            <span className="text">Sellers</span>
          </NavLink>
        </li>
      </ul>
    </aside>
    </>
  );
};

export default Header;
