import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Cookies from 'js-cookie';
import { increment, incrementAsync, selectCount } from '../authSlice';
import { Link, useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';

export default function Otp() {
  const count = useSelector(selectCount);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const csrftoken = Cookies.get('csrftoken');
  const { pk } = useParams(); // Get the primary key from the URL
  const [otp, setOtp] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [timer, setTimer] = useState(0); // Initial timer value
  const [message, setMessage] = useState('');
  const [showVerifyButton, setShowVerifyButton] = useState(true); // State to control the visibility of the "Verify OTP" button

  useEffect(() => {
    console.log('PK value:', pk);
  }, [pk]);

  useEffect(() => {
    let countdownInterval;
    if (timer > 0) {
      countdownInterval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    }

    if (timer === 0) {
      clearInterval(countdownInterval);
      setShowVerifyButton(true);
    }

    return () => clearInterval(countdownInterval);
  }, [timer]);

  const handleVerifyOTP = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');

    try {
      const response = await axios.patch(
        `https://terracharms.com/verify_seller_otp/${pk}/`,
        { otp }, // Send OTP value in the request body
        {
          headers: {
            'X-CSRFToken': csrftoken,
          },
        }
      );

      if (response.status === 200) {
        navigate(`/sellerform/${pk}`); // Navigate to a success page with fetchedPk
      } else {
        setError('Failed to verify OTP.');
      }
    } catch (error) {
      if (error.response && error.response.data) {
        setError(error.response.data.error);
      } else {
        setError(error.message || 'An unknown error occurred during OTP verification.');
      }
    } finally {
      setLoading(false);
    }
  };

  const handleResendOTP = async () => {
    if (!pk) {
      setMessage('Invalid request: primary key (pk) is missing.');
      return;
    }

    try {
      const response = await axios.patch(
        `https://terracharms.com/regenrate_seller_otp/${pk}/`,
        {},
        {
          headers: {
            'X-CSRFToken': csrftoken,
          },
        }
      );

      if (response.status === 200) {
        setMessage('OTP has been resent.');
        setTimer(15); // Restart the 15-second timer
        setShowVerifyButton(false);
      } else {
        setMessage('Failed to resend OTP.');
      }
    } catch (error) {
      if (error.response && error.response.data) {
        setMessage(error.response.data.error);
      } else {
        setMessage(error.message || 'An unknown error occurred. Please try again.');
      }
    }
  };

  return (
    <div className="mx-auto mt-12 bg-white max-w-2xl px-4 sm:px-6 lg:px-8 ">
      <div className="sm:mx-auto sm:w-full sm:max-w-sm">
      <img
                  className="h-16 w-16 ml-40"
                  src="https://cdn.dotpe.in/longtail/store-logo/8631177/mvNA0s1i.webp"
                  alt="StarkMart"
                />
        <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
          Please Verify Your Mobile Number
        </h2>
      </div>
      <div>
        <label
          htmlFor="number"
          className="block text-sm font-medium leading-6 text-gray-900"
        >
          OTP
        </label>
        <div className="mt-2">
          <input
            id="number"
            name="number"
            type="number"
            autoComplete="number"
            value={otp}
            onChange={(e) => setOtp(e.target.value)} // Bind the input to OTP state
            required
            className="block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          />
        </div>
      </div>
      <div className="mt-2">
        {showVerifyButton && (
          <button
            onClick={handleVerifyOTP}
            type="submit"
            className="flex w-full justify-center rounded-md bg-indigo-600 px-2 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            disabled={loading}
          >
            {loading ? 'Verifying...' : 'Verify OTP'}
          </button>
        )}
      </div>

      {error && <p className="mt-4 text-center text-sm text-red-500">{error}</p>}
      {message && <p className="mt-4 text-center text-sm text-green-500">{message}</p>}
      <p className="mt-10 text-center text-sm text-gray-500">
        Didn't receive the OTP{' '}
        <button
          onClick={handleResendOTP}
          type="button"
          className="font-semibold leading-6 text-indigo-600 hover:text-indigo-500"
          disabled={timer > 0}
        >
          {timer > 0 ? `Resend OTP in ${timer} seconds` : 'Resend OTP'}
        </button>
      </p>
    </div>
  );
}
