import SellerLogin from "../features/auth/components/SellerLogin";
function SellerLoginPage(){
    return(
        <div>
         <SellerLogin></SellerLogin> 
        </div>
    );

}

export default SellerLoginPage;