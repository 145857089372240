import React from 'react'
import { useParams } from 'react-router-dom'
import AUserSidebar from '../../components/AdminProfile/AUserSidebar'
import AAccountSettings from '../../components/AdminProfile/AAccountSettings'
import './AUserProfile.css'
import AUserAddress from '../../components/AdminProfile/AUserAddress'
import ALegalNotice from '../../components/AdminProfile/ALegalNotice'
import OrderScreen from '../../screens/OrderScreen'
import Footer from '../../../features/footer/Footer';
import Sidebar from '../../components/sidebar';
import Header from '../../components/Header'

const AUserProfile = () => {
    const {pk,activepage} = useParams()
  return (
    <>
    <Sidebar />
  
    <Header />
    
    <div className='userprofile'>
         <div className='userprofilein'>
            <div className='left'>
              <AUserSidebar activepage={activepage}/>
            </div>
              {activepage === `/admin/AccountSettings/${pk}` && <AAccountSettings/>}
              {activepage === `/admin/orders/${pk}` && <OrderScreen/>}
              {activepage === '/user/address' && <AUserAddress/>}
              {activepage === 'legalnotice' && <ALegalNotice/>}
         </div>
        </div>
   
         {/* <Footer className='mb-20'></Footer> */}
    </>
  )
}

export default AUserProfile
