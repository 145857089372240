// import React, { useState, useEffect, Fragment } from 'react';
// import ProductCard from './ProductCard';
// import { Dialog, Disclosure, Menu, Transition } from '@headlessui/react';
// import { XMarkIcon, ChevronDownIcon, MinusIcon, PlusIcon } from '@heroicons/react/24/outline';
// import Navbar from '../../../features/navbar/Navbar';
// import { useParams } from 'react-router-dom';
// import axios from 'axios';
// import "./AllProduct.css";

// const sortOptions = [
//   { name: 'Newest', value: 'newest' },
//   { name: 'Price: Low to High', value: 'lhprice' },
//   { name: 'Price: High to Low', value: 'hlprice' },
// ];

// const filters = [
//   {
//     id: "color",
//     name: "COLOR",
//     options: [
//       { value: "White", label: "White" },
//       { value: "Beige", label: "Beige" },
//       { value: "Red", label: "Red" },
//       { value: "Black", label: "Black" },
//       { value: "Brown", label: "Brown" },
//       { value: "Green", label: "Green" },
//       { value: "Purple", label: "Purple" },
//     ],
//   },
//   {
//     id: "size",
//     name: "SIZE",
//     options: [
//       { value: "Extra Large", label: "Extra Large" },
//       { value: "Large", label: "Large" },
//       { value: "Small", label: "Small" },
//       { value: "Medium", label: "Medium" },
//     ],
//   },
// ];

// function classNames(...classes) {
//   return classes.filter(Boolean).join(' ');
// }

// const AllProduct = () => {
//   const [mobileFiltersOpen, setMobileFiltersOpen] = useState(false);
//   const [products, setProducts] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [searchTerm, setSearchTerm] = useState('');
  
//   const [filterState, setFilterState] = useState({
//     sort: '',
//     color: '',
//     size: ''
//   });

//   // Define onSearch function
//   const handleSearch = (searchTerm) => {
//     setSearchTerm(searchTerm);
//   };
//     const { pk, id } = useParams();

//   useEffect(() => {
//     const fetchProductsData = async () => {
//       try {
//         const baseUrl = `https://terracharms.com/`;
//         let url = `${baseUrl}get_all_sub_products/${pk}/${id}/`; // Default URL
//         let params = {};

//         if (searchTerm) {
//           url = `${baseUrl}user_search/${pk}/`;
//           params = { search: searchTerm };
//         } else if (filterState.sort === "lhprice") {
//           url = `${baseUrl}sortascending/${pk}/${id}/`;
//         } else if (filterState.sort === "hlprice") {
//           url = `${baseUrl}sortdescending/${pk}/${id}/`;
//         } else if (filterState.sort === "newest") {
//           url = `${baseUrl}get_new_sub_products/${pk}/${id}/`;
//         } else if (filterState.color) {
//           url = `${baseUrl}filter_by_colour/${pk}/${id}/`;
//           params = { color: filterState.color };
//         } else if (filterState.size) {
//           url = `${baseUrl}filter_by_size/${pk}/${id}/`;
//           params = { Size: filterState.size };
//         }

//         const response = await axios.get(url, { params });
//         setProducts(response.data);
//         console.log(response.data);
//       } catch (error) {
//         console.error("Error fetching products:", error);
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchProductsData();
//   }, [filterState, searchTerm, pk, id]);

//   const filteredProducts = products.filter(product =>
//     product.product_name.toLowerCase().includes(searchTerm.toLowerCase())
//   );

//   // Handle filter changes
//   const handleSortChange = (value) => {
//     setFilterState({
//       sort: value,
//       color: '',
//       size: ''
//     });
//   };

//   const handleColorChange = (value) => {
//     setFilterState({
//       sort: '',
//       color: value,
//       size: ''
//     });
//   };

//   const handleSizeChange = (value) => {
//     setFilterState({
//       sort: '',
//       color: '',
//       size: value
//     });
//   };

//   if (loading) {
//     return <div className="loading-spinner">Loading...</div>;
//   }

//   return (
//     <div className="bg-white">
//  <Navbar onSearch={setSearchTerm} />      
//       <div>
//         {/* Mobile filter dialog */}
//         <Transition.Root show={mobileFiltersOpen} as={Fragment}>
//           <Dialog as="div" className="relative z-40 lg:hidden" onClose={setMobileFiltersOpen}>
//             <Transition.Child
//               as={Fragment}
//               enter="transition-opacity ease-linear duration-300"
//               enterFrom="opacity-0"
//               enterTo="opacity-100"
//               leave="transition-opacity ease-linear duration-300"
//               leaveFrom="opacity-100"
//               leaveTo="opacity-0"
//             >
//               <div className="fixed inset-0 bg-black bg-opacity-25" />
//             </Transition.Child>

//             <div className="fixed inset-0 z-40 flex">
//               <Transition.Child
//                 as={Fragment}
//                 enter="transition ease-in-out duration-300 transform"
//                 enterFrom="translate-x-full"
//                 enterTo="translate-x-0"
//                 leave="transition ease-in-out duration-300 transform"
//                 leaveFrom="translate-x-0"
//                 leaveTo="translate-x-full"
//               >
//                 <Dialog.Panel className="relative ml-auto flex h-full w-full max-w-xs flex-col overflow-y-auto bg-white py-4 pb-12 shadow-xl">
//                   <div className="flex items-center justify-between px-4">
//                     <h2 className="text-lg font-medium text-gray-900">Filters</h2>
//                     <button
//                       type="button"
//                       className="-mr-2 flex h-10 w-10 items-center justify-center rounded-md bg-white p-2 text-gray-400"
//                       onClick={() => setMobileFiltersOpen(false)}
//                     >
//                       <span className="sr-only">Close menu</span>
//                       <XMarkIcon className="h-6 w-6" aria-hidden="true" />
//                     </button>
//                   </div>

//                   {/* Filters */}
//                   <form className="mt-4 border-t border-gray-200">
//                     {filters.map((section) => (
//                       <Disclosure as="div" key={section.id} className="border-t border-gray-200 px-4 py-6">
//                         {({ open }) => (
//                           <>
//                             <h3 className="-mx-2 -my-3 flow-root">
//                               <Disclosure.Button className="flex w-full items-center justify-between bg-white px-2 py-3 text-gray-400 hover:text-gray-500">
//                                 <span className="font-medium text-gray-900">{section.name}</span>
//                                 <span className="ml-6 flex items-center">
//                                   {open ? (
//                                     <MinusIcon className="h-5 w-5" aria-hidden="true" />
//                                   ) : (
//                                     <PlusIcon className="h-5 w-5" aria-hidden="true" />
//                                   )}
//                                 </span>
//                               </Disclosure.Button>
//                             </h3>
//                             <Disclosure.Panel className="pt-6">
//                               <div className="space-y-6">
//                                 {section.options.map((option, optionIdx) => (
//                                   <div key={option.value} className="flex items-center">
//                                     <input
//                                       id={`filter-mobile-${section.id}-${optionIdx}`}
//                                       name={`${section.id}[]`}
//                                       type="radio"
//                                       value={option.value}
//                                       onChange={() => {
//                                         if (section.id === "color") {
//                                           handleColorChange(option.value);
//                                         } else if (section.id === "size") {
//                                           handleSizeChange(option.value);
//                                         }
//                                       }}
//                                       className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
//                                     />
//                                     <label
//                                       htmlFor={`filter-mobile-${section.id}-${optionIdx}`}
//                                       className="ml-3 min-w-0 flex-1 text-gray-500"
//                                     >
//                                       {option.label}
//                                     </label>
//                                   </div>
//                                 ))}
//                               </div>
//                             </Disclosure.Panel>
//                           </>
//                         )}
//                       </Disclosure>
//                     ))}
//                   </form>
//                 </Dialog.Panel>
//               </Transition.Child>
//             </div>
//           </Dialog>
//         </Transition.Root>

//         <main className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
//           <div className="flex items-baseline justify-between border-b border-gray-200 pb-6 pt-6">
//             <h1 className="text-4xl font-bold tracking-tight text-gray-900">Filters</h1>
//             <div className="flex items-center">
//               <Menu as="div" className="relative inline-block text-left">
//                 <div>
//                   <Menu.Button className="group inline-flex justify-center text-sm font-medium text-gray-700 hover:text-gray-900">
//                     Sort
//                     <ChevronDownIcon
//                       className="-mr-1 ml-1 h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
//                       aria-hidden="true"
//                     />
//                   </Menu.Button>
//                 </div>
//                 <Transition
//                   as={Fragment}
//                   enter="transition ease-out duration-100"
//                   enterFrom="transform opacity-0 scale-95"
//                   enterTo="transform opacity-100 scale-100"
//                   leave="transition ease-in duration-75"
//                   leaveFrom="transform opacity-100 scale-100"
//                   leaveTo="transform opacity-0 scale-95"
//                 >
//                   <Menu.Items className="absolute right-0 z-10 mt-2 w-40 origin-top-right rounded-md bg-white shadow-2xl ring-1 ring-black ring-opacity-5 focus:outline-none">
//                     <div className="py-1">
//                       {sortOptions.map((option) => (
//                         <Menu.Item key={option.value}>
//                           {({ active }) => (
//                             <button
//                               onClick={() => handleSortChange(option.value)}
//                               className={classNames(
//                                 active ? 'bg-gray-100' : '',
//                                 'block px-4 py-2 text-sm text-gray-700 w-full text-left'
//                               )}
//                             >
//                               {option.name}
//                             </button>
//                           )}
//                         </Menu.Item>
//                       ))}
//                     </div>
//                   </Menu.Items>
//                 </Transition>
//               </Menu>
//             </div>
//           </div>

//           <section aria-labelledby="products-heading" className="pt-6 pb-24">
//             <h2 id="products-heading" className="sr-only">Products</h2>
//             <div className="grid grid-cols-1 gap-x-8 gap-y-10 lg:grid-cols-4">
//               <form className="hidden lg:block">
//                 <h3 className="sr-only">Filters</h3>
//                 {filters.map((section) => (
//                   <Disclosure as="div" key={section.id} className="border-b border-gray-200 py-6">
//                     {({ open }) => (
//                       <>
//                         <h3 className="-my-3 flow-root">
//                           <Disclosure.Button className="flex w-full items-center justify-between bg-white py-3 text-sm text-gray-400 hover:text-gray-500">
//                             <span className="font-medium text-gray-900">{section.name}</span>
//                             <span className="ml-6 flex items-center">
//                               {open ? (
//                                 <MinusIcon className="h-5 w-5" aria-hidden="true" />
//                               ) : (
//                                 <PlusIcon className="h-5 w-5" aria-hidden="true" />
//                               )}
//                             </span>
//                           </Disclosure.Button>
//                         </h3>
//                         <Disclosure.Panel className="pt-6">
//                           <div className="space-y-4">
//                             {section.options.map((option, optionIdx) => (
//                               <div key={option.value} className="flex items-center">
//                                 <input
//                                   id={`filter-${section.id}-${optionIdx}`}
//                                   name={`${section.id}[]`}
//                                   type="radio"
//                                   value={option.value}
//                                   onChange={() => {
//                                     if (section.id === "color") {
//                                       handleColorChange(option.value);
//                                     } else if (section.id === "size") {
//                                       handleSizeChange(option.value);
//                                     }
//                                   }}
//                                   className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
//                                 />
//                                 <label
//                                   htmlFor={`filter-${section.id}-${optionIdx}`}
//                                   className="ml-3 text-sm text-gray-600"
//                                 >
//                                   {option.label}
//                                 </label>
//                               </div>
//                             ))}
//                           </div>
//                         </Disclosure.Panel>
//                       </>
//                     )}
//                   </Disclosure>
//                 ))}
//               </form>
//               <div className="lg:col-span-3">
//                <div className="product-list">
//                  <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 gap-x-6 gap-y-8">
//         {filteredProducts.map(product => (
//           <ProductCard key={product.id} product={product} />
//         ))}
//       </div>
//       </div>
//       </div>
//             </div>
//           </section>
//         </main>
//       </div>
//     </div>
//   );
// };

// export default AllProduct;

import React, { useState, useEffect, Fragment } from 'react';
import ProductCard from './ProductCard';
import { Dialog, Disclosure, Menu, Transition } from '@headlessui/react';
import { XMarkIcon, ChevronDownIcon, MinusIcon, PlusIcon } from '@heroicons/react/24/outline';
import Navbar from '../../../features/navbar/Navbar';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import "./AllProduct.css";

const sortOptions = [
  { name: 'Newest', value: 'newest' },
  { name: 'Price: Low to High', value: 'lhprice' },
  { name: 'Price: High to Low', value: 'hlprice' },
];

const filters = [
  {
    id: "color",
    name: "COLOR",
    options: [
      { value: "White", label: "White" },
      { value: "Beige", label: "Beige" },
      { value: "Red", label: "Red" },
      { value: "Black", label: "Black" },
      { value: "Brown", label: "Brown" },
      { value: "Green", label: "Green" },
      { value: "Purple", label: "Purple" },
    ],
  },
  {
    id: "size",
    name: "SIZE",
    options: [
      { value: "Extra Large", label: "Extra Large" },
      { value: "Large", label: "Large" },
      { value: "Small", label: "Small" },
      { value: "Medium", label: "Medium" },
    ],
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const AllProduct = () => {
  const [mobileFiltersOpen, setMobileFiltersOpen] = useState(false);
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  
  const [filterState, setFilterState] = useState({
    sort: '',
    color: '',
    size: ''
  });

  // Define onSearch function
  const handleSearch = (searchTerm) => {
    setSearchTerm(searchTerm);
  };
    const { pk, id } = useParams();
    const [sidebarOpen, setSidebarOpen] = useState(false);
  useEffect(() => {
    const fetchProductsData = async () => {
      try {
        const baseUrl = `https://terracharms.com/`;
        let url = `${baseUrl}get_all_sub_products/${pk}/${id}/`; // Default URL
        let params = {};

        if (searchTerm) {
          url = `${baseUrl}user_search/${pk}/`;
          params = { search: searchTerm };
        } else if (filterState.sort === "lhprice") {
          url = `${baseUrl}sortascending/${pk}/${id}/`;
        } else if (filterState.sort === "hlprice") {
          url = `${baseUrl}sortdescending/${pk}/${id}/`;
        } else if (filterState.sort === "newest") {
          url = `${baseUrl}get_new_sub_products/${pk}/${id}/`;
        } else if (filterState.color) {
          url = `${baseUrl}filter_by_colour/${pk}/${id}/`;
          params = { color: filterState.color };
        } else if (filterState.size) {
          url = `${baseUrl}filter_by_size/${pk}/${id}/`;
          params = { Size: filterState.size };
        }

        const response = await axios.get(url, { params });
        setProducts(response.data);
        console.log(response.data);
      } catch (error) {
        console.error("Error fetching products:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchProductsData();
  }, [filterState, searchTerm, pk, id]);

  const filteredProducts = products.filter(product =>
    product.product_name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Handle filter changes
  const handleSortChange = (value) => {
    setFilterState({
      sort: value,
      color: '',
      size: ''
    });
  };

  const handleColorChange = (value) => {
    setFilterState({
      sort: '',
      color: value,
      size: ''
    });
  };

  const handleSizeChange = (value) => {
    setFilterState({
      sort: '',
      color: '',
      size: value
    });
  };

  if (loading) {
    return <div className="loading-spinner">Loading...</div>;
  }

  return (
    <div className="bg-white">
 <Navbar onSearch={setSearchTerm} />      
      <div>
        {/* Mobile filter dialog */}
        <Transition.Root show={mobileFiltersOpen} as={Fragment}>
          <Dialog as="div" className="relative z-40 lg:hidden" onClose={setMobileFiltersOpen}>
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-black bg-opacity-25" />
            </Transition.Child>

            <div className="fixed inset-0 z-40 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="relative ml-auto flex h-full w-full max-w-xs flex-col overflow-y-auto bg-white py-4 pb-12 shadow-xl">
                  <div className="flex items-center justify-between px-4">
                    <h2 className="text-lg font-medium text-gray-900">Filters</h2>
                    <button
                      type="button"
                      className="-mr-2 flex h-10 w-10 items-center justify-center rounded-md bg-white p-2 text-gray-400"
                      onClick={() => setMobileFiltersOpen(false)}
                    >
                      <span className="sr-only">Close menu</span>
                      <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>

                  {/* Filters */}
                  <div className="relative overflow-hidden mt-4 mb-8 ml-5 mr-5">
  <div className="pb-40 pt-16">
    <div className="mx-auto max-w-7xl px-4 sm:static sm:px-6 lg:px-8">
      
      <div className="lg:flex lg:items-start">
        {/* Filters for Mobile */}
        <div className="lg:hidden">
            <div className="fixed inset-0 bg-gray-800 bg-opacity-75 z-40" aria-hidden="true" />
            <div className="fixed inset-0 flex z-50">
              <div className="relative flex-1 flex flex-col max-w-xs w-full bg-white">
                <div className="absolute top-0 right-0 -mr-12 pt-2">
                  <button
                    type="button"
                    className="ml-1 h-12 w-12 inline-flex items-center justify-center rounded-full text-gray-400 hover:text-gray-500"
                    aria-label="Close menu"
                    onClick={() => setSidebarOpen(false)}
                  >
                    <span className="sr-only">Close menu</span>
                    <svg className="h-6 w-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
                    </svg>
                  </button>
                </div>
                <div className="pt-5 pb-4">
                  <div className="flex items-center px-4">
                    <h2 className="text-lg font-semibold text-gray-900">Filter by</h2>
                  </div>
                  <div className="mt-5 px-2">
                    {/* Color Filter */}
                    <div>
                      <h3 className="text-md font-medium text-gray-900">Color</h3>
                      <div className="mt-2 space-y-2">
                        {filters
                          .find(section => section.id === "color")?.options
                          .map((option, optionIdx) => (
                            <div key={option.value} className="flex items-center">
                              <input
                                id={`color-filter-mobile-${optionIdx}`}
                                name="color"
                                type="radio"
                                value={option.value}
                                onChange={() => handleColorChange(option.value)}
                                className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                              />
                              <label
                                htmlFor={`color-filter-mobile-${optionIdx}`}
                                className="ml-3 text-gray-500"
                              >
                                {option.label}
                              </label>
                            </div>
                          ))}
                      </div>
                    </div>
                    {/* Size Filter */}
                    <div className="mt-4">
                      <h3 className="text-md font-medium text-gray-900">Size</h3>
                      <div className="mt-2 space-y-2">
                        {filters
                          .find(section => section.id === "size")?.options
                          .map((option, optionIdx) => (
                            <div key={option.value} className="flex items-center">
                              <input
                                id={`size-filter-mobile-${optionIdx}`}
                                name="size"
                                type="radio"
                                value={option.value}
                                onChange={() => handleSizeChange(option.value)}
                                className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                              />
                              <label
                                htmlFor={`size-filter-mobile-${optionIdx}`}
                                className="ml-3 text-gray-500"
                              >
                                {option.label}
                              </label>
                            </div>
                          ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        

        {/* Sidebar for Mobile */}
        <div className="lg:hidden mt-8 bg-gray-100 border border-gray-200 p-4 rounded-md">
          <h2 className="text-lg font-semibold text-gray-900">Filter by</h2>
          <div className="mt-4">
            <h3 className="text-md font-medium text-gray-900">Color</h3>
            <div className="space-y-2">
              {filters
                .find(section => section.id === "color")?.options
                .map((option, optionIdx) => (
                  <div key={option.value} className="flex items-center">
                    <input
                      id={`color-filter-mobile-${optionIdx}`}
                      name="color"
                      type="radio"
                      value={option.value}
                      onChange={() => handleColorChange(option.value)}
                      className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                    />
                    <label
                      htmlFor={`color-filter-mobile-${optionIdx}`}
                      className="ml-3 text-gray-500"
                    >
                      {option.label}
                    </label>
                  </div>
                ))}
            </div>
          </div>
          <div className="mt-4">
            <h3 className="text-md font-medium text-gray-900">Size</h3>
            <div className="space-y-2">
              {filters
                .find(section => section.id === "size")?.options
                .map((option, optionIdx) => (
                  <div key={option.value} className="flex items-center">
                    <input
                      id={`size-filter-mobile-${optionIdx}`}
                      name="size"
                      type="radio"
                      value={option.value}
                      onChange={() => handleSizeChange(option.value)}
                      className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                    />
                    <label
                      htmlFor={`size-filter-mobile-${optionIdx}`}
                      className="ml-3 text-gray-500"
                    >
                      {option.label}
                    </label>
                  </div>
                ))}
            </div>
          </div>
        </div>

        {/* Desktop Filters */}
        <form className="mt-4 border-t border-gray-200 hidden lg:block lg:w-1/4">
          {filters.map((section) => (
            <Disclosure as="div" key={section.id} className="border-t border-gray-200 px-4 py-6">
              {({ open }) => (
                <>
                  <h3 className="-mx-2 -my-3 flow-root">
                    <Disclosure.Button className="flex w-full items-center justify-between bg-white px-2 py-3 text-gray-400 hover:text-gray-500">
                      <span className="font-medium text-gray-900">{section.name}</span>
                      <span className="ml-6 flex items-center">
                        {open ? (
                          <MinusIcon className="h-5 w-5" aria-hidden="true" />
                        ) : (
                          <PlusIcon className="h-5 w-5" aria-hidden="true" />
                        )}
                      </span>
                    </Disclosure.Button>
                  </h3>
                  <Disclosure.Panel className="pt-6">
                    <div className="space-y-6">
                      {section.options.map((option, optionIdx) => (
                        <div key={option.value} className="flex items-center">
                          <input
                            id={`filter-desktop-${section.id}-${optionIdx}`}
                            name={`${section.id}[]`}
                            type="radio"
                            value={option.value}
                            onChange={() => {
                              if (section.id === "color") {
                                handleColorChange(option.value);
                              } else if (section.id === "size") {
                                handleSizeChange(option.value);
                              }
                            }}
                            className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                          />
                          <label
                            htmlFor={`filter-desktop-${section.id}-${optionIdx}`}
                            className="ml-3 min-w-0 flex-1 text-gray-500"
                          >
                            {option.label}
                          </label>
                        </div>
                      ))}
                    </div>
                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>
          ))}
        </form>

        {/* Sidebar for Desktop */}
        <div className="hidden lg:block lg:w-1/4 lg:ml-4 bg-gray-100 border border-gray-200 p-4 rounded-md">
          <h2 className="text-lg font-semibold text-gray-900">Filter by</h2>
          <div className="mt-4">
            <h3 className="text-md font-medium text-gray-900">Color</h3>
            <div className="space-y-2">
              {filters
                .find(section => section.id === "color")?.options
                .map((option, optionIdx) => (
                  <div key={option.value} className="flex items-center">
                    <input
                      id={`color-filter-desktop-${optionIdx}`}
                      name="color"
                      type="radio"
                      value={option.value}
                      onChange={() => handleColorChange(option.value)}
                      className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                    />
                    <label
                      htmlFor={`color-filter-desktop-${optionIdx}`}
                      className="ml-3 text-gray-500"
                    >
                      {option.label}
                    </label>
                  </div>
                ))}
            </div>
          </div>
          <div className="mt-4">
            <h3 className="text-md font-medium text-gray-900">Size</h3>
            <div className="space-y-2">
              {filters
                .find(section => section.id === "size")?.options
                .map((option, optionIdx) => (
                  <div key={option.value} className="flex items-center">
                    <input
                      id={`size-filter-desktop-${optionIdx}`}
                      name="size"
                      type="radio"
                      value={option.value}
                      onChange={() => handleSizeChange(option.value)}
                      className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                    />
                    <label
                      htmlFor={`size-filter-desktop-${optionIdx}`}
                      className="ml-3 text-gray-500"
                    >
                      {option.label}
                    </label>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

                </Dialog.Panel>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>

        <main className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <div className="flex items-baseline justify-between border-b border-gray-200 pb-6 pt-6">
            <h1 className="text-4xl font-bold tracking-tight text-gray-900">Filters</h1>
            <div className="flex items-center">
              <Menu as="div" className="relative inline-block text-left">
                <div>
                  <Menu.Button className="group inline-flex justify-center text-sm font-medium text-gray-700 hover:text-gray-900">
                    Sort
                    <ChevronDownIcon
                      className="-mr-1 ml-1 h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
                      aria-hidden="true"
                    />
                  </Menu.Button>
                </div>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="absolute right-0 z-10 mt-2 w-40 origin-top-right rounded-md bg-white shadow-2xl ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div className="py-1">
                      {sortOptions.map((option) => (
                        <Menu.Item key={option.value}>
                          {({ active }) => (
                            <button
                              onClick={() => handleSortChange(option.value)}
                              className={classNames(
                                active ? 'bg-gray-100' : '',
                                'block px-4 py-2 text-sm text-gray-700 w-full text-left'
                              )}
                            >
                              {option.name}
                            </button>
                          )}
                        </Menu.Item>
                      ))}
                    </div>
                  </Menu.Items>
                </Transition>
              </Menu>
            </div>
          </div>

          <section aria-labelledby="products-heading" className="pt-6 pb-24">
            <h2 id="products-heading" className="sr-only">Products</h2>
            <div className="grid grid-cols-1 gap-x-8 gap-y-10 lg:grid-cols-4">
              <form className="hidden lg:block">
                <h3 className="sr-only">Filters</h3>
                {filters.map((section) => (
                  <Disclosure as="div" key={section.id} className="border-b border-gray-200 py-6">
                    {({ open }) => (
                      <>
                        <h3 className="-my-3 flow-root">
                          <Disclosure.Button className="flex w-full items-center justify-between bg-white py-3 text-sm text-gray-400 hover:text-gray-500">
                            <span className="font-medium text-gray-900">{section.name}</span>
                            <span className="ml-6 flex items-center">
                              {open ? (
                                <MinusIcon className="h-5 w-5" aria-hidden="true" />
                              ) : (
                                <PlusIcon className="h-5 w-5" aria-hidden="true" />
                              )}
                            </span>
                          </Disclosure.Button>
                        </h3>
                        <Disclosure.Panel className="pt-6">
                          <div className="space-y-4">
                            {section.options.map((option, optionIdx) => (
                              <div key={option.value} className="flex items-center">
                                <input
                                  id={`filter-${section.id}-${optionIdx}`}
                                  name={`${section.id}[]`}
                                  type="radio"
                                  value={option.value}
                                  onChange={() => {
                                    if (section.id === "color") {
                                      handleColorChange(option.value);
                                    } else if (section.id === "size") {
                                      handleSizeChange(option.value);
                                    }
                                  }}
                                  className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                />
                                <label
                                  htmlFor={`filter-${section.id}-${optionIdx}`}
                                  className="ml-3 text-sm text-gray-600"
                                >
                                  {option.label}
                                </label>
                              </div>
                            ))}
                          </div>
                        </Disclosure.Panel>
                      </>
                    )}
                  </Disclosure>
                ))}
              </form>
              <div className="lg:col-span-3">
               <div className="product-list">
                 <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 gap-x-6 gap-y-8">
        {filteredProducts.map(product => (
          <ProductCard key={product.id} product={product} />
        ))}
      </div>
      </div>
      </div>
            </div>
          </section>
        </main>
      </div>
    </div>
  );
};

export default AllProduct;
