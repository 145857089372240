// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import './UserAddress.css';
// import { useParams } from 'react-router-dom';

// const AUserAddress = () => {
//     const [show, setShow] = useState(false);
//     const [addresses, setAddresses] = useState([]);
//     const [formData, setFormData] = useState({
//         name:'',
//         address: '',
//         city: '',
//         country: '',
//         state: '',
//         zipcode: '',
//     });
//     const [isEditing, setIsEditing] = useState(false);
//     const [editAddressId, setEditAddressId] = useState(null);

//     const { pk, id } = useParams();

//     useEffect(() => {
//         const fetchAddresses = async () => {
//             try {
//                 const response = await axios.get(`https://terracharms.com/user_address/${pk}/`);
//                 setAddresses(response.data);
//             } catch (error) {
//                 console.error("Error fetching addresses:", error);
//             }
//         };

//         fetchAddresses();
//     }, [pk]);

//     const handleInputChange = (e) => {
//         const { name, value } = e.target;
//         setFormData((prevFormData) => ({
//             ...prevFormData,
//             [name]: value,
//         }));
//     };

//     const handleSave = async () => {
//         try {
//             if (isEditing) {
//                 await axios.patch(`https://terracharms.com/edit_user_address/${pk}/${editAddressId}/`, formData);
//                 alert('Address updated successfully');
//             } else {
//                 await axios.post(`https://terracharms.com/add_address/${pk}/`, formData);
//                 alert('Address added successfully');
//             }
//             setShow(false);
//             setIsEditing(false);
//             setEditAddressId(null);
//             setFormData({
//                 name:'',
//                 address: '',
//                 city: '',
//                 country: '',
//                 state: '',
//                 zipcode: '',
//             });
//             // Fetch updated addresses
//             const response = await axios.get(`https://terracharms.com/user_address/${pk}/`);
//             setAddresses(response.data);
//         } catch (error) {
//             console.error('Error saving address:', error);
//             alert('Failed to save address');
//         }
//     };
//       const handleDelete = async (id) => {
//         try {
//             await axios.delete(`https://terracharms.com/delete_user_address/${pk}/${id}/`);
//             alert('Address deleted successfully');
//             // Fetch updated addresses
//             const addressResponse = await axios.get(`https://terracharms.com/user_address/${pk}/`);
//             setAddresses(addressResponse.data);
//         } catch (error) {
//             console.error('Error deleting address:', error);
//             alert('Failed to delete address');
//         }
//     };

//     const handleEdit = (address) => {
//         setFormData(address);
//         setIsEditing(true);
//         setEditAddressId(address.id);
//         setShow(true);
//     };

//     return (
//         <div className='useraddress'>
//             {!show && <h1 className='mainhead1'>Your Address</h1>}
//             {!show && (
//                 <div className='addressin'>
//                     {addresses.map((item, index) => (
//                         <div className='address' key={index}>
//                             <span>{item.name}</span>,
//                             <span>{item.address}</span>,
//                             <span>{item.city}</span>,
//                             <span>{item.state}</span>,
//                             <span>{item.zipcode}</span>,
//                             <span>{item.country}</span>
//                             <div className='editbtn mt-4' onClick={() => handleEdit(item)}>
//                                 <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
//                                     <path strokeLinecap="round" strokeLinejoin="round" d="M16.862 3.487a2.25 2.25 0 013.182 3.183L7.265 19.45a4.5 4.5 0 01-1.73 1.047l-4.5 1.35a.75.75 0 01-.92-.92l1.35-4.5a4.5 4.5 0 011.047-1.73L16.862 3.487z" />
//                                 </svg>
//                             </div>
//                             <div className='delbtn'>
//                                 <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
//                                     <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
//                                 </svg>
//                             </div>
//                             <div className='delbtn' onClick={() => handleDelete(item.id)}>
//                             <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
//                                 <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
//                             </svg>
//                         </div>
//                         </div>
//                     ))}
//                 </div>
//             )}
//             {!show && (
//                 <div className='addnewbtn' onClick={() => setShow(true)}>
//                     <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
//                         <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
//                     </svg>
//                 </div>
//             )}
//             {show && (
//                 <div className='addnew'>
//                     <h1 className='mainhead1'>{isEditing ? 'Edit Address' : 'Add New Address'}</h1>
//                     <div className='form'>
//                         <div className='form-group'>
//                             <label htmlFor='name'>Name</label>
//                             <input type="text" name="name" value={formData.name} onChange={handleInputChange} />
//                         </div>
//                         <div className='form-group'>
//                             <label htmlFor='address'>Address</label>
//                             <input type="text" name="address" value={formData.address} onChange={handleInputChange} />
//                         </div>
//                         <div className='form-group'>
//                             <label htmlFor='city'>City</label>
//                             <input type="text" name="city" value={formData.city} onChange={handleInputChange} />
//                         </div>
//                         <div className='form-group'>
//                             <label htmlFor='state'>State</label>
//                             <input type="text" name="state" value={formData.state} onChange={handleInputChange} />
//                         </div>
//                         <div className='form-group'>
//                             <label htmlFor='zipcode'>Zipcode</label>
//                             <input type="text" name="zipcode" value={formData.zipcode} onChange={handleInputChange} />
//                         </div>
//                         <div className='form-group'>
//                             <label htmlFor='country'>Country</label>
//                             <input type="text" name="country" value={formData.country} onChange={handleInputChange} />
//                         </div>
//                     </div>
//                     <button className='mainbutton1' onClick={handleSave}>Save</button>
//                 </div>
//             )}
//         </div>
//     );
// }

// export default AUserAddress;

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './UserAddress.css';
import { useParams } from 'react-router-dom';

const AUserAddress = () => {
    const [show, setShow] = useState(false);
    const [addresses, setAddresses] = useState([]);
    const [formData, setFormData] = useState({
        name: '',
        address: '',
        city: '',
        country: 'India', // Default to India
        state: '',
        zipcode: '',
    });
    const [isEditing, setIsEditing] = useState(false);
    const [editAddressId, setEditAddressId] = useState(null);

    const { pk } = useParams();

    useEffect(() => {
        const fetchAddresses = async () => {
            try {
                const response = await axios.get(`https://terracharms.com/user_address/${pk}/`);
                setAddresses(response.data);
            } catch (error) {
                console.error('Error fetching addresses:', error);
            }
        };

        fetchAddresses();
    }, [pk]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        // Set limit of 6 characters for the zipcode
        if (name === 'zipcode' && value.length > 6) {
            return;
        }

        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
    };

    const handleSave = async () => {
        try {
            if (isEditing) {
                await axios.patch(`https://terracharms.com/edit_user_address/${pk}/${editAddressId}/`, formData);
                alert('Address updated successfully');
            } else {
                await axios.post(`https://terracharms.com/add_address/${pk}/`, formData);
                alert('Address added successfully');
            }
            setShow(false);
            setIsEditing(false);
            setEditAddressId(null);
            setFormData({
                name: '',
                address: '',
                city: '',
                country: 'India', // Reset to default
                state: '',
                zipcode: '',
            });

            // Fetch updated addresses
            const response = await axios.get(`https://terracharms.com/user_address/${pk}/`);
            setAddresses(response.data);
        } catch (error) {
            console.error('Error saving address:', error);
            alert('Failed to save address');
        }
    };

    const handleDelete = async (id) => {
        try {
            await axios.delete(`https://terracharms.com/delete_user_address/${pk}/${id}/`);
            alert('Address deleted successfully');
            const addressResponse = await axios.get(`https://terracharms.com/user_address/${pk}/`);
            setAddresses(addressResponse.data);
        } catch (error) {
            console.error('Error deleting address:', error);
            alert('Failed to delete address');
        }
    };

    const handleEdit = (address) => {
        setFormData(address);
        setIsEditing(true);
        setEditAddressId(address.id);
        setShow(true);
    };

    return (
        <div className="useraddress">
            {!show && <h1 className="mainhead1">Your Address</h1>}
            {!show && (
                <div className="addressin">
                    {addresses.map((item, index) => (
                        <div className="address" key={index}>
                            <span>{item.name}</span>,
                            <span>{item.address}</span>,
                            <span>{item.city}</span>,
                            <span>{item.state}</span>,
                            <span>{item.zipcode}</span>,
                            <span>{item.country}</span>
                            <div className="editbtn mt-4" onClick={() => handleEdit(item)}>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M16.862 3.487a2.25 2.25 0 013.182 3.183L7.265 19.45a4.5 4.5 0 01-1.73 1.047l-4.5 1.35a.75.75 0 01-.92-.92l1.35-4.5a4.5 4.5 0 011.047-1.73L16.862 3.487z" />
                                </svg>
                            </div>
                            <div className="delbtn">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                </svg>
                            </div>
                            <div className="delbtn" onClick={() => handleDelete(item.id)}>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                </svg>
                            </div>
                        </div>
                    ))}
                </div>
            )}
            {!show && (
                <div className="addnewbtn" onClick={() => setShow(true)}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                    </svg>
                </div>
            )}
            {show && (
                <div className="addnew">
                    <h1 className="mainhead1">{isEditing ? 'Edit Address' : 'Add New Address'}</h1>
                    <div className="form">
                        <div className="form-group">
                            <label htmlFor="name">Name</label>
                            <input type="text" name="name" value={formData.name} onChange={handleInputChange} />
                        </div>
                        <div className="form-group">
                            <label htmlFor="address">Address</label>
                            <input type="text" name="address" value={formData.address} onChange={handleInputChange} />
                        </div>
                        <div className="form-group">
                            <label htmlFor="city">City</label>
                            <input type="text" name="city" value={formData.city} onChange={handleInputChange} />
                        </div>
                        <div className="form-group">
                            <label htmlFor="state">State</label>
                            <input type="text" name="state" value={formData.state} onChange={handleInputChange} />
                        </div>
                        <div className="form-group">
                            <label htmlFor="zipcode">Zipcode</label>
                            <input type="text" name="zipcode" value={formData.zipcode} onChange={handleInputChange} maxLength="6" />
                        </div>
                        <div className="form-group">
                            <label htmlFor="country">Country</label>
                            <select name="country" value={formData.country} onChange={handleInputChange}>
                                <option value="India">India</option>
                                <option value="Canada">Canada</option>
                            </select>
                        </div>
                    </div>
                    <button className="mainbutton1" onClick={handleSave}>Save</button>
                </div>
            )}
        </div>
    );
}

export default AUserAddress;
