import React from 'react'
import { useParams } from 'react-router-dom'
import SUserSidebar from '../../components/SellerProfile/SUserSidebar'
import SAccountSettings from '../../components/SellerProfile/SAccountSettings'
import './SUserProfile.css'
import SUserAddress from '../../components/SellerProfile/SUserAddress'
import SLegalNotice from '../../components/SellerProfile/SLegalNotice'
import SOrderScreen from '../../screens/OrderScreen';
import Sidebar from '../../components/sidebar';
import Header from '../../components/Header';
const SUserProfile = () => {
    const {pk,activepage} = useParams()
  return (
    <>
    <Sidebar />
   
    <Header />
    
    <div className='userprofile'>
         <div className='userprofilein '>
            <div className='left  item-black-300 '>
              <SUserSidebar activepage={activepage}/>
            </div>
              {activepage === 'accountsettings' && <SAccountSettings/>}
              {activepage === `/seller/orders/${pk}` && <SOrderScreen />}
              {activepage === '/user/address' && <SUserAddress/>}
              {activepage === 'legalnotice' && <SLegalNotice/>}
         </div>
         
        </div>
   
   
    </>
  )
}

export default SUserProfile