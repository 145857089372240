// import React, { useState, useEffect } from "react";
// import { Link, useParams, useNavigate } from "react-router-dom";
// import { toast } from "react-toastify";
// import Footer from "../../../features/footer/Footer";
// import Navbar from "../../../features/navbar/Navbar";
// import axios from "axios";
// import "./ProductPage.css";
// import Slider from "react-slick";

// function ProductPage() {
//   const { pk, id } = useParams();
//   const [productdata, setProductdata] = useState({});
//   const [activeImg, setActiveImg] = useState({});
//   const [imageset, setImageset] = useState([]);
//   const [count, setCount] = useState(1);
//   const [searchTerm, setSearchTerm] = useState('');
//   const navigate = useNavigate();
//   const [products, setProducts] = useState([]);

//   useEffect(() => {
//     if (searchTerm.trim() !== '') {
//       const fetchSearchResults = async () => {
//         try {
//           const response = await axios.get(
//             `https://terracharms.com/user_search_get/${pk}/`,
//             {
//               params: {
//                 search: searchTerm
//               }
//             }
//           );
//           navigate(`/user/allproducts/${pk}/`);
//         } catch (error) {
//           console.error('Error fetching search results:', error);
//         }
//       };
//       fetchSearchResults();
//     }
//   }, [searchTerm, pk, navigate]);

//   useEffect(() => {
//     const getProductDataById = async () => {
//       try {
//         let response = await axios.get(
//           `https://terracharms.com/get_product/${pk}/${id}/`
//         );
//         if (!response.data) {
//           throw new Error("Failed to fetch product data");
//         }
//         let product = response.data;
//         setProductdata(product);
//         setActiveImg({ id: 1, image: product.product_image });
//         setImageset([
//           { id: 1, image: product.product_image },
//           { id: 2, image: product.product_image2 },
//           { id: 3, image: product.product_image3 },
//         ]);
//       } catch (error) {
//         console.error("Error fetching product data:", error);
//         toast.error("Failed to fetch product details");
//       }
//     };
//     getProductDataById();
//     window.scroll(0, 0);
//   }, [pk, id]);

//   const settings1 = {
//     dots: true,
//     infinite: true,
//     speed: 500,
//     slidesToShow: 7,
//     slidesToScroll: 1,
//     autoplay: true,
//     autoplaySpeed: 3000,
//     arrows: true,
//   };

//   useEffect(() => {
//     const fetchRecentSearched = async () => {
//       try {
//         const response = await axios.get(`https://terracharms.com/recent_searched_product/${pk}/`);
//         setProducts(response.data);
//       } catch (error) {
//         console.error('Error fetching recent searched products:', error);
//       }
//     };
//     fetchRecentSearched();
//   }, [pk]);

//   const handlerecentsearchedclick = (id) => {
//     navigate(`/user/productpage/${pk}/${id}/`);
//   };

//   const handleImageClick = (item) => {
//     setActiveImg(item);
//   };

//   const addtoCart = async () => {
//     try {
//       const response = await axios.patch(
//         `https://terracharms.com/add_to_cart/${pk}/${id}/`,
        
//       );
//       console.log(response.data.id)
//       if (!response.data) {
//         throw new Error("Failed to add item to cart");
//       }

//       toast.success("Item added to cart");
//     } catch (error) {
//       console.error("Error adding item to cart:", error);
//       toast.error("Failed to add item to cart");
//     }
//   };


//   const handleShare = () => {
//     if (navigator.share) {
//       navigator.share({
//         title: productdata.product_name,
//         url: `https://terracharms.com/get_product/${pk}/${id}/`
//       }).then(() => {
//         console.log('Thanks for sharing!');
//       }).catch((error) => {
//         console.error('Error sharing:', error);
//       });
//     } else {
//       alert('Sharing is not supported on this browser.');
//     }
//   };

//   return (
//     <>
//       <Navbar onSearch={setSearchTerm} />
//       <div className="mt-6"></div>
//       <div className="productpage">
//         <div className="pc1">
//           <div className="c11">
//             <div className="imgset">
//               {imageset &&
//                 imageset.map((item) => (
//                   <div
//                     className="imgsmall h-full w-full"
//                     key={item.id}
//                     onClick={() => handleImageClick(item)}
//                   >
//                     <img
//                       src={`https://terracharms.com/${item.image}`}
//                       alt={`Image ${item.id}`}
//                       className={activeImg.id === item.id ? "active" : ""}
//                     />
//                   </div>
//                 ))}
//             </div>
//             <div className="imgbig">
//               <div className="zoom-container">
//                 <div className="zoom-image">
//                   <img
//                     src={`https://terracharms.com/${activeImg.image}`}
//                     alt={`Image ${activeImg.id}`}
//                     className="active-image"
//                   />
//                 </div>
//                 <button className="share-button" onClick={handleShare}>
//                   <i className="fas fa-share-alt"></i>
//                 </button>
//               </div>
//             </div>
//           </div>

//           <div className='c12'>
//             <h5 className='text-3xl font-bold text-gray-900'>{productdata.product_name}</h5>
//             <div className='c12 ml-4' >
//               <p className='price'>
//                 ₹{productdata.discount_price * count}
//                 <span>₹{productdata.actual_price * count}</span>
//               </p>
//             </div>
//             <div className="btncont">
//               <Link to={`/Cart/${pk}`}>
//                 <button onClick={addtoCart}>Add to Cart</button> 
//               </Link>
//               <Link to={`/Cart/${pk}`}>
//                 <button onClick={addtoCart}>Buy Now</button>
//               </Link>
//             </div>
//             <div className="py-10 lg:col-span-2 lg:col-start-1 lg:border-r lg:border-gray-200 lg:pb-16 lg:pr-8 lg:pt-6">
//               <div>
//                 <h1 className="font-bold text-gray-900">Description</h1>
//                 <div className="space-y-6">
//                   <p className="text-base text-gray-900 ml-2">{productdata.description}</p>
//                 </div>
//               </div>
//               <div className="mt-10">
//                 <h1 className="font-bold text-gray-900">Highlights</h1>
//                 <div className="space-y-6">
//                   <p className="text-base  ml-2 text-gray-900">{productdata.Highlights}</p>
//                 </div>
//               </div>
//               <div className="mt-10">
//                 <h1 className="font-bold text-gray-900">Details</h1>
//                 <div className="space-y-6">
//                   <p className="text-gray-900 ml-2 text-base">{productdata.Details}</p>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//         <hr />
//         <div className="pt-2">
//           <h2 className="text-2xl font-bold text-gray-900 text-left ml-2">You Also May Like</h2>
//           <div className="mt-6 mb-14">
//             <Slider {...settings1}>
//               {products.length > 0 ? (
//                 products.map((product) => (
//                   <div
//                     key={product.id}
//                     className="group relative px-3 bg-white"
//                     onClick={() => handlerecentsearchedclick(product.id)}
//                   >
//                     <div className="box-border p-2 border-1 border-grey rounded-md">
//                       <div className="aspect-h-1 aspect-w-1 w-full overflow-hidden">
//                         <img
//                           alt={product.product_name}
//                           src={`https://terracharms.com${product.product_image}`}
//                           className="h-full w-full object-cover object-center lg:h-full lg:w-full transition-transform duration-300 ease-in-out transform group-hover:scale-110"
//                         />
//                       </div>
//                     </div>
//                     <div className="mt-2 mb-4 flex justify-between">
//                       <div>
//                         <h3 className="text-sm text-gray-700">
//                           <a href="">
//                             <span aria-hidden="true" className="absolute inset-0" />
//                             {product.product_name}
//                           </a>
//                         </h3>
//                       </div>
//                     </div>
//                   </div>
//                 ))
//               ) : (
//                 <p>No similar products available</p>
//               )}
//             </Slider>
//           </div>
//         </div>
//         <Footer />
//       </div>
//     </>
//   );
// }

// export default ProductPage;


import React, { useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Footer from "../../../features/footer/Footer";
import Navbar from "../../../features/navbar/Navbar";
import axios from "axios";
import "./ProductPage.css";
import Slider from "react-slick";

function ProductPage() {
  const { pk, id } = useParams();
  const [productdata, setProductdata] = useState({});
  const [activeImg, setActiveImg] = useState({});
  const [imageset, setImageset] = useState([]);
  const [count, setCount] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const navigate = useNavigate();
  const [products, setProducts] = useState([]);

  useEffect(() => {
    if (searchTerm.trim() !== '') {
      const fetchSearchResults = async () => {
        try {
          const response = await axios.get(
            `https://terracharms.com/user_search_get/${pk}/`,
            {
              params: {
                search: searchTerm
              }
            }
          );
          navigate(`/user/allproducts/${pk}/`);
        } catch (error) {
          console.error('Error fetching search results:', error);
        }
      };
      fetchSearchResults();
    }
  }, [searchTerm, pk, navigate]);

  useEffect(() => {
    const getProductDataById = async () => {
      try {
        let response = await axios.get(
          `https://terracharms.com/get_product/${pk}/${id}/`
        );
        if (!response.data) {
          throw new Error("Failed to fetch product data");
        }
        let product = response.data;
        setProductdata(product);
        setActiveImg({ id: 1, image: product.product_image });
        setImageset([
          { id: 1, image: product.product_image },
          { id: 2, image: product.product_image2 },
          { id: 3, image: product.product_image3 },
        ]);
      } catch (error) {
        console.error("Error fetching product data:", error);
        toast.error("Failed to fetch product details");
      }
    };
    getProductDataById();
    window.scroll(0, 0);
  }, [pk, id]);

  const settings1 = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 7,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: true,
  };
  // Mobile settings
const mobileSettings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 2,
  slidesToScroll: 1,
  arrows: true,
  autoplay: true,
  autoplaySpeed: 3000,
};

  useEffect(() => {
    const fetchRecentSearched = async () => {
      try {
        const response = await axios.get(`https://terracharms.com/recent_searched_product/${pk}/`);
        setProducts(response.data);
      } catch (error) {
        console.error('Error fetching recent searched products:', error);
      }
    };
    fetchRecentSearched();
  }, [pk]);

  const handlerecentsearchedclick = (id) => {
    navigate(`/user/productpage/${pk}/${id}/`);
  };

  const handleImageClick = (item) => {
    setActiveImg(item);
  };

  const addtoCart = async () => {
    try {
      const response = await axios.patch(
        `https://terracharms.com/add_to_cart/${pk}/${id}/`,
        
      );
      console.log(response.data.id)
      if (!response.data) {
        throw new Error("Failed to add item to cart");
      }

      toast.success("Item added to cart");
    } catch (error) {
      console.error("Error adding item to cart:", error);
      toast.error("Failed to add item to cart");
    }
  };


  const handleShare = () => {
    if (navigator.share) {
      navigator.share({
        title: productdata.product_name,
        url: `https://terracharms.com/get_product/${pk}/${id}/`
      }).then(() => {
        console.log('Thanks for sharing!');
      }).catch((error) => {
        console.error('Error sharing:', error);
      });
    } else {
      alert('Sharing is not supported on this browser.');
    }
  };

  // Calculate discount percentage
  const discountPercentage = productdata.actual_price && productdata.discount_price 
    ? ((productdata.actual_price - productdata.discount_price) / productdata.actual_price * 100).toFixed(2)
    : null;

  return (
    <>
      <Navbar onSearch={setSearchTerm} />
      <div className="mt-6"></div>
      <div className="productpage">
        <div className="pc1">
          <div className="c11">
            <div className="imgset">
              {imageset &&
                imageset.map((item) => (
                  <div
                    className="imgsmall h-full w-full"
                    key={item.id}
                    onClick={() => handleImageClick(item)}
                  >
                    <img
                      src={`https://terracharms.com/${item.image}`}
                      alt={`Image ${item.id}`}
                      className={activeImg.id === item.id ? "active" : ""}
                    />
                  </div>
                ))}
            </div>
            <div className="imgbig">
              <div className="zoom-container">
                <div className="zoom-image">
                  <img
                    src={`https://terracharms.com/${activeImg.image}`}
                    alt={`Image ${activeImg.id}`}
                    className="active-image"
                  />
                </div>
                <button className="share-button" onClick={handleShare}>
                  <i className="fas fa-share-alt"></i>
                </button>
              </div>
            </div>
          </div>

          <div className='c12'>
            <h5 className='text-2xl  text-gray-900'>{productdata.product_name}</h5>
            <div className="price-container">
  <p className="price">
    ₹{(productdata.discount_price * count).toFixed(2)}
    <span className="actual-price">₹{(productdata.actual_price * count).toFixed(2)}</span>
  </p>
  {discountPercentage && (
    <span className="discount-box">
      {discountPercentage}% OFF
    </span>
  )}
</div>

            <div className="btncont">
              <Link to={`/Cart/${pk}`}>
                <button onClick={addtoCart}>Add to Cart</button> 
              </Link>
              <Link to={`/Cart/${pk}`}>
                <button onClick={addtoCart}>Buy Now</button>
              </Link>
            </div>
            <div className="py-10 lg:col-span-2 lg:col-start-1 lg:border-r lg:border-gray-200 lg:pb-16 lg:pr-8 lg:pt-6">
              <div>
                <h1 className="font-bold text-1xl text-gray-900">Description</h1>
                <div className="space-y-6">
                  <p className="text-base  text-gray-900 ml-2">{productdata.description}</p>
                </div>
              </div>
              <div className="mt-10">
                <h1 className="font-bold text-gray-900">Highlights</h1>
                <div className="space-y-6">
                  <p className="text-base  ml-2 text-gray-900">{productdata.Highlights}</p>
                </div>
              </div>
              <div className="mt-10">
                <h1 className="font-bold text-gray-900">Details</h1>
                <div className="space-y-6">
                  <p className="text-gray-900 ml-2 text-base">{productdata.Details}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr />
        <div className="bg-white mt-6 mx-4 sm:mx-6 lg:mx-8 lg:block hidden">
          <h2 className="text-2xl font-bold text-gray-900 text-left ml-5 mr-5">More in Category</h2>
          <div className="mt-6 mb-14">
            <Slider {...settings1}>
              {products.length > 0 ? (
                products.map((product) => (
                  <div
                    key={product.id}
                    className="group relative px-3 bg-white"
                    onClick={() => handlerecentsearchedclick(product.id)}
                  >
                    <div className="box-border p-2 border-1 border-grey rounded-md">
                      <div className="aspect-h-1 aspect-w-1 w-full overflow-hidden">
                        <img
                          alt={product.product_name}
                          src={`https://terracharms.com${product.product_image}`}
                          className="h-full w-full object-cover object-center lg:h-full lg:w-full transition-transform duration-300 ease-in-out transform group-hover:scale-110"
                        />
                      </div>
                    </div>
                    <div className="mt-2 mb-4 flex justify-between">
                      <div className="">
                  <h3 className="title text-truncate text-sm text-gray-700">
                          <a href="">
                            <span aria-hidden="true" className="absolute inset-0"></span>
                            {product.product_name}
                          </a>
                        </h3>
                        <p className="mt-1 text-sm text-gray-500">Color: {product.colour}</p>
                      </div>
                      <p className="text-sm font-medium text-gray-900">₹{product.discount_price}</p>
                    </div>
                  </div>
                ))
              ) : (
                <p>No products found.</p>
              )}
            </Slider>
          </div>
        </div>
        {/* Mobile Version */}
<div className="bg-white mt-6 mx-1 sm:mx-1 lg:hidden">
  <div className="pt-6 bg-white">
    <h2 className="text-xl sm:text-2xl font-bold text-gray-900 text-left">More in Category</h2>
    <div className="mt-6 mb-14">
      <Slider {...mobileSettings}>
        {products.length > 0 ? (
          products.map((product) => (
            <div
              key={product.id}
              className="group relative px-3 bg-white"
              onClick={() => handlerecentsearchedclick(product.id, product.pk)}
            >
              <div className="box-border p-2 border border-gray-300 rounded-md">
                <div className="aspect-w-1 aspect-h-1 w-full overflow-hidden">
                  <img
                    alt={product.product_name}
                    src={`https://terracharms.com${product.product_image}`}
                    className="h-full w-full object-cover transition-transform duration-300 ease-in-out transform group-hover:scale-110"
                  />
                </div>
              </div>
              <div className="mt-2 mb-4 flex justify-between">
              <div className="info-wrapp">
                  <h3 className="title text-truncate text-sm sm:text-base text-gray-700">
                    {product.product_name}
                  </h3>
                </div>
              </div>
            </div>
          ))
        ) : (
          <p>No similar products available</p>
        )}
      </Slider>
    </div>
  </div>
</div>
      </div>
      <Footer />
    </>
  );
}

export default ProductPage;
