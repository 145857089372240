import { useState, Fragment } from "react";
import { Link } from "react-router-dom";
import { Disclosure, Transition } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
// import { Link, Element, scroller } from 'react-scroll';
import './seller.css';
    const faqs = [
      {
        question: "What is Starmart?",
        answer: "Starmart is India's leading e-commerce platform, offering a wide range of products including artifacts , showpieces , home essentials decor , and more."
      },
      {
        question: "How do I place an order on Starmart?",
        answer: "To place an order on Starmart, simply browse the products, add your chosen items to the cart, and proceed to checkout. You can pay using various payment methods including credit/debit cards, net banking, UPI, or cash on delivery."
      },
      {
        question: "What are the delivery charges?",
        answer: "Delivery charges vary depending on the seller and location. Some sellers offer free delivery, while others may charge a nominal fee based on the distance and delivery time."
      },
      {
        question: "Can I return or exchange a product?",
        answer: "Yes, Flipkart offers a hassle-free return and exchange policy. You can request a return or exchange within a specified period from the date of delivery, subject to the terms and conditions of the seller."
      },
      {
        question: "How can I track my order?",
        answer: "You can track your order by logging into your Flipkart account and visiting the 'My Orders' section. You will also receive updates on your registered mobile number and email."
      },
      {
        question: "What payment methods are available?",
        answer: "Starmart offers multiple payment methods including credit/debit cards, net banking, UPI, e-wallets, and cash on delivery."
      },
      {
        question: "Is it safe to shop on Starmart?",
        answer: "Yes, Starmart takes your security very seriously. We use advanced encryption technologies to protect your personal and payment information."
      },
    ];
    const blogs = [
            {
               id: 1,
               title: "Maximizing Your Sales on Starmart",
               summary: "Learn the best strategies to boost your sales on Flipkart. From optimizing your product listings to effective marketing, this blog covers it all.",
              
            },
           {
               id: 2,
               title: "How to Manage Inventory Efficiently",
               summary: "Efficient inventory management is key to maintaining a successful online store. Discover tips and tools to keep your inventory in check.",
              
           },
           
             {
               id: 3,
               title: "Effective Customer Service Strategies",
               summary: "Providing top-notch customer service can set you apart from other sellers. Learn how to enhance your customer service and increase customer satisfaction.",
             
             },
             {
               id: 4,
               title: "The Importance of Product Reviews",
               summary: "Product reviews can make or break your sales. Learn how to encourage positive reviews and handle negative feedback effectively.",
              
           },
           {
             id: 5,
             title: "Building a Strong Brand on Starmart",
             summary: "Your brand is your identity in the marketplace. Discover ways to build and maintain a strong brand presence on Starmart.",
             
             },
            
           ];

const categories = [
  { id: 1, name: "Create Account", href: "#create-account" },
  { id: 2, name: "List of Products", href: "#list-products" },
  { id: 3, name: "Storage and Shipping", href: "#storage-shipping" },
  { id: 4, name: "Receive Payment", href: "#receive-payment" },
];

const categories1 = [
  { id: 1, name: "Payment Cycle", href: "#payment-cycle" },
  { id: 2, name: "Fee Type", href: "#fee-type" },
  { id: 3, name: "Calculate Gross Margin", href: "#calculate-margin" },
];

const categories2 = [
  { id: 1, name: "FAQs", href: "#faqs" },
  { id: 2, name: "Seller Blogs", href: "#seller-blogs" },
];


function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function SNavbar({ children }) {
  const [sellonlineOpen, setSellOnlineOpen] = useState(false);
  const [commissionOpen, setCommissionOpen] = useState(false);
  const [learnOpen, setLearnOpen] = useState(false);

  const [expandedIndex, setExpandedIndex] = useState(null);
  const toggleFAQ = index => {
    setExpandedIndex(expandedIndex === index ? null : index);
  };
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  

  

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };
  return (
    <>
    <div className="min-h-full">
    <Disclosure as="nav" className="bg-blue-900 h-20">
      {({ open }) => (
        <>
          <header>
            <div className="max-w-7xl mr-4 px-2 sm:px-6 lg:px-8">
              <div className="flex justify-between h-16 items-center">
                {/* Logo and Title */}
                <div className="flex items-center">
                  <div className="flex-shrink-0 ml-1 mr-2 flex items-center">
                    <img
                      className="h-20 w-20 mt-2"
                      src="https://cdn.dotpe.in/longtail/store-logo/8631177/mvNA0s1i.webp"
                      alt="StarMart"
                    />
                    <span className="ml-3 text-2xl sm:text-4xl mt-3 text-white">The StarkMart</span>
                  </div>
                </div>

                {/* Desktop Menu */}
                <div className="hidden mt-6 mr-4 md:flex md:ml-6 md:space-x-8">
                  <span className="relative">
                    <button
                      onClick={() => {
                        setSellOnlineOpen(!sellonlineOpen);
                        setCommissionOpen(false);
                        setLearnOpen(false);
                      }}
                      className="text-gray-100 hover:text-gray-600 focus:outline-none text-lg"
                    >
                      Sell Online
                    </button>
                    <Transition
                      show={sellonlineOpen}
                      enter="transition duration-100 ease-out"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition duration-75 ease-out"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      {(ref) => (
                        <div
                          ref={ref}
                          className="absolute mt-2 w-52 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                        >
                          {categories.map((category) => (
                            <a key={category.id} href={category.href} className="block px-4 py-2 text-gray-700 hover:bg-gray-100">
                              {category.name}
                            </a>
                          ))}
                        </div>
                      )}
                    </Transition>
                  </span>

                  <span className="relative">
                    <button
                      onClick={() => {
                        setCommissionOpen(!commissionOpen);
                        setSellOnlineOpen(false);
                        setLearnOpen(false);
                      }}
                      className="text-gray-100 hover:text-gray-600 focus:outline-none text-lg"
                    >
                      Fees and Commission
                    </button>
                    <Transition
                      show={commissionOpen}
                      enter="transition duration-100 ease-out"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition duration-75 ease-out"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      {(ref) => (
                        <div
                          ref={ref}
                          className="absolute mt-2 w-52 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                        >
                          {categories1.map((category) => (
                            <a key={category.id} href={category.href} className="block px-4 py-2 text-gray-700 hover:bg-gray-100">
                              {category.name}
                            </a>
                          ))}
                        </div>
                      )}
                    </Transition>
                  </span>

                  <span className="relative">
                    <button
                      onClick={() => {
                        setLearnOpen(!learnOpen);
                        setCommissionOpen(false);
                        setSellOnlineOpen(false);
                      }}
                      className="text-gray-100 hover:text-gray-600 focus:outline-none text-lg"
                    >
                      Learn
                    </button>
                    <Transition
                      show={learnOpen}
                      enter="transition duration-100 ease-out"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition duration-75 ease-out"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      {(ref) => (
                        <div
                          ref={ref}
                          className="absolute mt-2 w-52 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                        >
                          {categories2.map((category) => (
                            <a key={category.id} href={category.href} className="block px-4 py-2 text-gray-700 hover:bg-gray-100">
                              {category.name}
                            </a>
                          ))}
                        </div>
                      )}
                    </Transition>
                  </span>
                  <div className="flex items-center  space-x-4">
              <Link to="/sellerlogin">
                <button className="rounded-md border border-transparent bg-white px-4 py-2 text-blue-900 hover:bg-yellow-500">
                  Login
                </button>
              </Link>
              <Link to="/sellersignup">
                <button className="rounded-md border border-transparent bg-white px-4 py-2 text-blue-900 hover:bg-yellow-500">
                  Start Selling
                </button>
              </Link>
            </div>
                </div>
              

                {/* Mobile Menu Button */}
                <div className="md:hidden flex items-center">
                  <button onClick={toggleSidebar} className="text-white">
                    {isSidebarOpen ? (
                      <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                    ) : (
                      <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                    )}
                  </button>
                </div>
              </div>
            </div>

              

              {/* Mobile Menu */}
              <Transition
                show={isSidebarOpen}
                enter="transition-transform transform duration-200 ease-in"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transition-transform transform duration-200 ease-out"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                {(ref) => (
                  <div
                    ref={ref}
                    className="fixed inset-0 z-40 bg-gray-800 bg-opacity-75"
                  >
                    <div className="fixed inset-0 flex justify-end">
                      <div className="w-64 bg-white py-6 px-4">
                        <button onClick={toggleSidebar} className="absolute top-4 right-4 text-gray-500">
                          <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                        </button>
                        <div className="space-y-4">
                        <Transition
  show={isSidebarOpen}
  enter="transition-transform transform duration-200 ease-in"
  enterFrom="translate-x-full"
  enterTo="translate-x-0"
  leave="transition-transform transform duration-200 ease-out"
  leaveFrom="translate-x-0"
  leaveTo="translate-x-full"
>
  {(ref) => (
    <div
      ref={ref}
      className="fixed inset-0 z-40 bg-gray-800 bg-opacity-75"
    >
      <div className="fixed inset-0 flex justify-end">
        <div className="w-64 bg-white py-6 px-4">
          <button onClick={toggleSidebar} className="absolute top-4 right-4 text-gray-500">
            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
          </button>
          <div className="space-y-4">
            <a href="#sell-online" className="block py-2 text-gray-700">
              Sell Online
            </a>
            <a href="#fees-commission" className="block py-2 text-gray-700">
              Fees and Commission
            </a>
            <a href="#learn" className="block py-2 text-gray-700">
              Learn
            </a>
            <Link to="/sellerlogin" className="block py-2 text-gray-700">
              Login
            </Link>
            <Link to="/sellersignup" className="block py-2 text-gray-700">
              Signup
            </Link>
          </div>
        </div>
      </div>
    </div>
  )}
</Transition>

                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </Transition>
            </header>
          </>
        )}
      </Disclosure>



       <header>
  <div className="h-42">
          <img
            src="/images/seller2.png"
            className="w-100 h-30"
          />
        </div>
  </header> 
        <div  className="create-account-container">
          <div className="faq-header">
            <h1 className="text-3xl font-bold py-4">How It Works</h1>
          </div>

          <div className="steps-container">
            <div className="step-card">
              <div className="step-circle">1</div>
              <div  className="step-title">Create Account</div>
              <ul>
                <li>GSTIN (for GST sellers)/ UIN (for non-GST sellers)</li>
                <li>Bank Account</li>
              </ul>
            </div>
            <div className="step-card">
              <div className="step-circle">2</div>
              <div className="step-title">List Products</div>
              <ul>
                <li>List the products you want to sell in your supplier panel</li>
              </ul>
            </div>
            <div className="step-card">
              <div className="step-circle">3</div>
              <div className="step-title">Get Orders</div>
              <ul>
                <li>Start getting orders from crores of Indians actively shopping on our platform.</li>
              </ul>
            </div>
            <div className="step-card">
              <div className="step-circle">4</div>
              <div className="step-title">Receive Payments</div>
              <ul>
                <li>Payments are deposited directly to your bank account following a 7-day payment cycle from order delivery.</li>
              </ul>
            </div>
          </div>
            <div className="benefits-container">
            <div className="benefit-card">
              <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQiphQqq4rpGUMXeUNWGMJB5RXpiRdZiD58zg&s" alt="No Registration Fee" className="w-20"/>
              <div className="benefit-title">No Registration Fee</div>
              <p>Registering as a Starktrack seller is free - no cost of creating your account or getting your products listed.</p>
            </div>
            <div className="benefit-card">
              <img src="https://cdn-icons-png.flaticon.com/256/9291/9291559.png" alt="No Collection Fee" className="w-20"/>
              <div className="benefit-title">No Collection Fee</div>
              <p>You keep 100% of the sale price with no charges on both payment gateway on StarMart.</p>
            </div>
            <div className="benefit-card">
              <img src="https://cdn-icons-png.flaticon.com/512/11671/11671690.png" alt="No Penalty" className="w-20"/>
              <div className="benefit-title">No Penalty</div>
              <p>Sell on Starktrack stress-free without the fear of penalties for order cancellations.</p>
            </div>
          </div>

          <div id="faqs" className="faq-container">
            <h2 id="learn"className="faq-heading">Frequently Asked Questions</h2>            
            {faqs.map((faq, index) => (
              <div key={index} className="faq-item">
                <div
                  className="faq-question"
                  onClick={() => toggleFAQ(index)}
                >
                  {faq.question}
                </div>
                {expandedIndex === index && (
                  <div className="faq-answer">
                    <p>{faq.answer}</p>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
      <div id="seller-blogs" className="seller-blogs">
      <h2 className="blog-heading">Seller Blogs</h2>
      <div  className="blog-list">
        {blogs.map(blog => (
          <div key={blog.id} className="blog-item">
            <h3>{blog.title}</h3>
            <p>{blog.summary}</p>
          </div>
        ))}          
      </div>
    </div>
    <div id="payment-cycle" className="payment-cycle-container">
    <h1 id="fee-type" className="payment-cycle-title">Payment Cycle for Sellers on Starmart</h1>
      
      <section id="fees-commission" className="payment-cycle-section">
        <h2 className="payment-cycle-subtitle">1. Understanding the Payment Cycle</h2>
        
        <h3 className="payment-cycle-subheading">1.1 Payment Frequency</h3>
        <p className="payment-cycle-text">
          Payments to sellers are processed on a <strong>weekly</strong> basis. The payment cycle begins on <strong>Monday</strong> and ends on <strong>Sunday</strong>. Payments for all orders successfully delivered within this period will be processed in the next cycle.
        </p>
        
        <h3 className="payment-cycle-subheading">1.2 Payment Schedule</h3>
        <ul className="payment-cycle-list">
          <li><strong>Order Delivered:</strong> Payments are initiated for orders delivered within the cycle.</li>
          <li><strong>Order Return:</strong> If an order is returned by the customer within the return window, the payment will be adjusted accordingly in the next cycle.</li>
          <li><strong>Refunds:</strong> Any refunds processed will be deducted from the upcoming payments.</li>
        </ul>
      </section>

      <section id="calculate-margin" className="payment-cycle-section">
      <div id="fees-commission" className="payment-cycle-heading">
          <h2 className="payment-cycle-subtitle">2.Payment Breakdown</h2>
          <img 
            src="https://png.pngtree.com/png-vector/20231127/ourmid/pngtree-asian-business-woman-pointing-on-copy-space-advertisement-png-image_10717615.png" 
            alt="Example Illustration" 
            className="payment-cycle-img" 
          />
        </div>
        
        <h3 className="payment-cycle-subheading">2.1 Order Payment</h3>
        <p className="payment-cycle-text">
          The payment you receive is calculated as follows:
          <code className="payment-cycle-code">
            Order Payment = Selling Price - Commission - Shipping Fee - Applicable Taxes
          </code>
        </p>
        
        <ul className="payment-cycle-list">
          <li><strong>Selling Price:</strong> The price at which the product was sold.</li>
          <li><strong>Commission:</strong> A percentage of the selling price, depending on the category.</li>
          <li><strong>Shipping Fee:</strong> Depending on the delivery location and product weight.</li>
          <li><strong>Applicable Taxes:</strong> Includes GST and other relevant taxes.</li>
        </ul>

        
        <div className="payment-cycle-heading">
          <h2 className="payment-cycle-subtitle">Example Calculation</h2>
          <img 
            src="https://png.pngtree.com/png-vector/20231223/ourmid/pngtree-woman-working-sell-online-owner-png-image_11095912.png" 
            alt="Example Illustration" 
            className="payment-cycle-img1" 
          />
        </div>
        <p className="payment-cycle-text">
          For example, if a product is sold at $100, with a 10% commission, $5 shipping fee, and $2.5 tax:
        </p>
      
        <ul className="payment-cycle-list">
          <li><strong>Selling Price:</strong> $100</li>
          <li><strong>Commission (10%):</strong> -$10</li>
          <li><strong>Shipping Fee:</strong> -$5</li>
          <li><strong>Tax:</strong> -$2.5</li>
          <li><strong>Total Payment:</strong> $82.5</li>
        </ul>
      </section>

      <section className="payment-cycle-section">
        <h2 className="payment-cycle-subtitle">3. Tracking Your Payments</h2>
        
        <h3 className="payment-cycle-subheading">3.1 Payment Dashboard</h3>
        <p className="payment-cycle-text">
          You can track all your payments through the <strong>Payment Dashboard</strong> in your seller account. The dashboard provides:
        </p>
        <ul className="payment-cycle-list">
          <li><strong>Earnings Summary:</strong> Overview of your total sales, deductions, and net earnings.</li>
          <li><strong>Payment Status:</strong> Track the status of each payment, including pending, processed, and completed payments.</li>
          <li><strong>Order Details:</strong> View detailed breakdowns of each order and the associated earnings.</li>
        </ul>
        
        <h3 className="payment-cycle-subheading">3.2 Payment Reports</h3>
        <p className="payment-cycle-text">
          Download detailed payment reports for specific periods to manage your finances better. Reports include:
        </p>
        <ul className="payment-cycle-list">
          <li>Order-wise breakdown</li>
          <li>Refund and return adjustments</li>
          <li>Detailed fee and commission structures</li>
        </ul>
      </section>

      <section className="payment-cycle-section">
        <h2 className="payment-cycle-subtitle">4. Managing Returns and Refunds</h2>
        
        <h3 className="payment-cycle-subheading">4.1 Return Adjustments</h3>
        <p className="payment-cycle-text">
          In the case of product returns, the payment for that order will be adjusted in the next payment cycle. The refund amount will be deducted from your pending payments.
        </p>
        
        <h3 className="payment-cycle-subheading">4.2 Refund Handling</h3>
        <p className="payment-cycle-text">
          If a customer is refunded, the amount will be deducted from your upcoming payments. You will be notified of all refunds and the reasons through your seller dashboard.
        </p>
      </section>

      <section className="payment-cycle-section">
        <h2 className="payment-cycle-subtitle">5. Payment Issues and Support</h2>
        
        <h3 className="payment-cycle-subheading">5.1 Common Payment Issues</h3>
        <ul className="payment-cycle-list">
          <li><strong>Payment Delays:</strong> Occasionally, payments might be delayed due to banking holidays or technical issues.</li>
          <li><strong>Discrepancies:</strong> If you notice any discrepancies in your payment, report them immediately through the seller support portal.</li>
        </ul>
        
        <h3 className="payment-cycle-subheading">5.2 Contacting Support</h3>
        <p className="payment-cycle-text">
          For any payment-related queries or issues, reach out to our <strong>Seller Support Team</strong> through:
        </p>
        <ul className="payment-cycle-list">
          <li><strong>Email:</strong> seller-support@starmart.com</li>
          <li><strong>Phone:</strong> +1-800-123-4567</li>
          <li><strong>Live Chat:</strong> Available in your seller dashboard</li>
        </ul>
      </section>

     
  
    </div>
    
    <div id="create-account" className="create-account-container">
      <h1  className="create-account-title">Create Account</h1>
      <p className="create-account-description">
        Creating your Starmart seller account is a quick process, taking less than 10 minutes, and requires only 3 documents. Follow the checklist to ensure a seamless account creation experience. By having these documents ready, you can streamline the account creation process and get started on Starmart as an online seller in no time.
      </p>

     

      <div id="sell-online" className="gst-section">
        <h2 className="gst-title">Don’t have a GSTIN?</h2>
        <p className="gst-description">
          Follow the steps below to generate one for your online business:
        </p>
        <ul className="gst-steps">
          <li>Register / Login to <a href="https://www.gst.gov.in" target="_blank" rel="noopener noreferrer">www.gst.gov.in</a></li>
          <li>Fill in the GST Enrolment Application Form</li>
          <li>Submit Enrolment Application</li>
        </ul>
      </div>

      <div className="categories-section">
        <h2 className="categories-title">Popular categories to sell across India</h2>
        <p className="categories-description">
          Starmart offers a diverse range of over 3000+ categories where you can sell your products. These categories represent just a fraction of the wide variety available on Starmart, providing ample opportunities for sellers to showcase their products to a large customer base.
        </p>
        <div className="popular-categories">
            <h2 className="text-2xl font-bold py-4 ">Popular Categories to Sell Online</h2>
            <div className="categories-grid ml-6">
              <div className="category-card">Sell HandCraft Artifacts</div>
              <div className="category-card">Sell HandCraft Artifacts</div>
              <div className="category-card">Sell HandCraft Artifacts</div>
              <div className="category-card">Sell HandCraft Artifacts</div>
              <div className="category-card">Sell HandCraft Artifacts</div>
              <div className="category-card">Sell HandCraft Artifacts</div>
              <div className="category-card">Sell HandCraft Artifacts</div>
              <div className="category-card">Sell HandCraft Artifacts</div>
              <div className="category-card">Sell HandCraft Artifacts</div>
              <div className="category-card">Sell HandCraft Artifacts</div>
            </div>
          </div>
      </div>
    </div>
    <div id="list-products" className="list-products-container">
      <div className="content-section">
        <h1 className="list-products-title">List Products</h1>       
         <div className="payment-cycle-heading">
         <p className="list-products-description">
          What is a listing? A listing refers to the process of registering your product on the Starmart platform, making it visible to customers, and enabling them to view and purchase your product. It involves creating a detailed product page that includes essential information such as product title, description, images, pricing, and other relevant details. A well-crafted listing helps attract potential customers and facilitates the sale of your product on Starmart.
        </p>
          <img 
            src="https://png.pngtree.com/png-vector/20231223/ourmid/pngtree-woman-working-sell-online-owner-png-image_11095912.png" 
            alt="Example Illustration" 
            className="payment-cycle-img2" 
          />
        </div>
 
        <p className="list-products-highlight">
          Did you know that providing precise and comprehensive information about your product, along with clear and captivating images, can increase its visibility on our platform by up to 15%.
        </p>
        <p className="list-products-subheading">
          Listing on Starmart can be done in 2 different ways:
        </p>
        <ol className="listing-methods">
          <li>
            <strong>Match with existing products</strong><br />
            If your product is already available on Starmart, you have the option to seamlessly link or 'Latch' onto the existing product listing. This allows you to make your product live on the platform without the need to create a separate listing.
          
          </li>
          <li>
            <strong>New product</strong><br />
            For products not currently available on Starmart, you'll need to provide complete information and create a new listing with details like description, dimensions, features, images, and usage instructions.
           
          </li>
        </ol>
      </div>
   
    </div>
    <div id="storage-shipping" className="storage-shipping-container">
      <h1 className="storage-shipping-title">Storage and Shipping</h1>
      <div className="storage-section">
        <h2 className="section-title">Storage Guidelines</h2>
       
        <div className="payment-cycle-heading">
        <p className="section-description">
          Proper storage of your products ensures that they remain in pristine condition until they are shipped to the customer. Follow these guidelines to optimize your product storage:
        </p>
          <img 
            src="https://static.vecteezy.com/system/resources/previews/018/928/873/non_2x/sell-products-online-png.png" 
            alt="Example Illustration" 
            className="payment-cycle-img1 w-50" 
          />
        </div>
        <ul className="guidelines-list">
          <li>Store products in a cool, dry place to avoid any damage from humidity.</li>
          <li>Keep products in their original packaging to protect them from dust and damage.</li>
          <li>Organize your inventory by category or SKU for easy retrieval and packaging.</li>
          <li>Regularly check the storage conditions and ensure no pests or rodents are present.</li>
          <li>Use proper shelving units that can support the weight of your products without bending or collapsing.</li>
        </ul>
      </div>
      <div className="shipping-section">
        <h2 className="section-title">Shipping Guidelines</h2>
        <p className="section-description">
          Ensuring that your products are shipped safely and on time is crucial for maintaining customer satisfaction. Adhere to the following shipping guidelines:
        </p>
        <ul className="guidelines-list">
          <li>Use sturdy and appropriately sized boxes to protect products during transit.</li>
          <li>Include cushioning materials such as bubble wrap or foam peanuts to prevent damage.</li>
          <li>Seal the packages securely with high-quality tape to avoid any accidental openings.</li>
          <li>Clearly label packages with the correct shipping address and contact details.</li>
          <li>Choose reliable courier services that offer tracking and insurance options.</li>
        </ul>
      </div>
    </div>
    <div id="receive-payment" className="return-payment-container">
      <h1 className="return-payment-title">Return Payment Process</h1>
      <div className="return-payment-section">
        <h2 className="section-title">Understanding the Return Payment</h2>
        <p className="section-description">
          Return payments are processed when a customer returns a product. It is essential to understand the process and guidelines to ensure a smooth transaction and maintain customer satisfaction.
        </p>
       
        <div className="payment-cycle-heading">
        <ul className="process-list">
          <li>Once a return request is initiated by the customer, the product undergoes a quality check.</li>
          <li>If the product passes the quality check, the return is approved, and the payment process begins.</li>
          <li>The refund amount is typically credited back to the customer’s original payment method within a specific time frame.</li>
          <li>Keep track of the return status and refund timelines through your seller dashboard.</li>
          <li>If the return is not approved due to quality check failure, the product is sent back to the customer, and no refund is processed.</li>
        </ul>
          <img 
            src="https://png.pngtree.com/png-clipart/20211205/original/pngtree-illustration-of-online-shop-digital-payment-with-tranparent-background-png-image_6960640.png" 
            alt="Example Illustration" 
            className="payment-cycle-img1 w-50" 
          />
        </div>
      </div>
      <div className="payment-timelines-section">
        <h2 className="section-title">Refund Timelines</h2>
        <p className="section-description">
          Refund timelines can vary depending on the payment method and the customer's bank. Below are the general timelines for different payment methods:
        </p>
        <ul className="timelines-list">
          <li><strong>Credit/Debit Card:</strong> 5-7 business days</li>
          <li><strong>Net Banking:</strong> 3-5 business days</li>
          <li><strong>UPI:</strong> 2-4 business days</li>
          <li><strong>Wallets:</strong> 24-48 hours</li>
          <li><strong>Cash on Delivery (COD):</strong> Refund to bank account within 7-10 business days</li>
        </ul>
      </div>
    </div>
    </>
  );
}

export default SNavbar;
